import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Banner from "../components/sharedComponents/Banner";
import Spinner from "../components/sharedComponents/Spinner";
import Auth from "../utils/auth";
export default function CashPaymentConfirmation() {
  const location = useLocation();
  const { data, newAmount } = location.state;
  const titular = localStorage.getItem("cash_titular");
  const cashAmount = localStorage.getItem("cash_amount");
  const comission = localStorage.getItem("comission");
  const lastFourDigits = localStorage.getItem("lastFourDigits");
  const reference = localStorage.getItem("cash_id");
  const email = localStorage.getItem("email");
  let isDist = localStorage.getItem("mtd")
  const navigate = useNavigate();
  const postalCode = localStorage.getItem("postalCode") || "";
  const [isLoading, setIsLoading] = useState(true);
  const mtd = localStorage.getItem("mtd");
  const mtdObj = mtd ? JSON.parse(mtd) : null;
  const isDistributor = mtdObj && Object.keys(mtdObj).length > 0;
  const reg = mtdObj?.region;
  const handleContinueClick = async (e) => {
    e.preventDefault();
    let isDist = localStorage.getItem("mtd")
    if (isDist) {
      localStorage.clear();
      window.location.href = 'https://www.distribuidor.telcel.com:4475/Portal-Distribuidores/app/login'
    } else {
      navigate("/home");
    }
  };

  const expireAt = localStorage.getItem("expirationDate"); // 2024-10-05 16:13"
  const date = new Date(expireAt);

  const day = date.getDate();
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();

  const monthNames = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  const month = monthNames[date.getMonth()];

  const formattedDate = `${day} de ${month} de ${year} a las ${hour}:${
    minute < 10 ? "0" : ""
  }${minute}`;

  const [fzaVta, setFzaVta] = useState("");
  
  useEffect(() => {
    if(isDist){   
      const Fza = JSON.parse(localStorage.getItem("mtd"));
      const FzaDinamico = Fza.fzaVtaPadre ? Fza.fzaVtaPadre : "";
      setFzaVta(FzaDinamico);
      }
    if (!Auth.loggedIn()) {
      navigate("/home");
      return;
    }
    const timer = setTimeout(() => {
      setIsLoading(false); 
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
  window.dlMetri.push({
    event : "pageLoad",
    pagina: {
      canal : "Proteccion Movil Amigo Kit",
      seccion : "Pago",
      pageName : "Pago|Pago en efectivo|OXXO",
    },
    usuario : {
      CP : postalCode ? postalCode : "",
      region : reg ? reg : "",
      perfil : isDistributor ? "Distribuidor" : "Usuario"
    }
  })
  console.log('dlMetri /pago en efectivo', window.dlMetri);
  window.dlMetri.push({
    event: "transaccionExitosa",
    pagina: {
      canal: "Proteccion Movil Amigo Kit",
      seccion: "Estas muy cerca de proteger tu equipo",
      pageName: "Estas muy cerca de proteger tu equipo",
    },
    usuario: {
      CP: postalCode ? postalCode : "",
      region: reg ? reg : "",
      perfil: isDistributor ? "Distribuidor" : "Usuario",
    },
    productos: [
      {
        categoria: "Servicios",
        subcategoria: "Seguridad",
        nombre: "Proteccion Movil Amigo Kit",
        monto: newAmount,
      },
    ],
    transaccion: {
      id: data.charges.data[0].payment_method.reference,
      metPago: "Efectivo",
      variante: "Oxxo",
      tipoTarjeta: "",
    },
  });
  console.log("dlMetri oxxo", window.window.dlMetri);
}, []);
  return (
    <section className="font-gotham min-h-screen place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      { isDist ?
      <p className="absolute bg-primary text-white px-3 py-1 top-0 right-0 rounded-bl-lg">
        {fzaVta}
      </p> : 
      null
      }
      <Banner />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="flex flex-col  justify-center item-center phone:row-span-4  laptop:col-span-6 py-10 ">
          <h1 className="text-2xl   text-primary font-semibold phone:px-8 text-center mb-8">
            ¡Estás muy cerca de proteger tu equipo!
          </h1> 

          <p className="text-[19.4px]/[28px] text-[#6D80A4] phone:px-8 text-center mb-8 laptop:w-[49vw]">
            Acude a cualquier tienda OXXO con la referencia que recibirás por
            correo electrónico y realiza el pago en efectivo.
          </p>
          <div className="flex flex-col justify-center laptop:items-start phone:items-start">
            <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4 font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                <span className="font-bold">Nombre: </span>
              </p>
              <p className="text-[#6D80A4] laptop:text-xl phone:text-base pl-1">
                {" "}
                {titular}
              </p>
            </div>
            <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4 font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                <span className="font-bold"> Correo electrónico: </span>
              </p>
              <p className="text-[#6D80A4] laptop:text-xl phone:text-base pl-1">
                {email}
              </p>
            </div>
            <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4 font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-bold">
                {" "}
                <span>Monto a pagar: </span>{" "}
              </p>
              <p className="pl-1 text-[#6D80A4] laptop:text-xl phone:text-base ">
                {"   "}${cashAmount}{" "}
                <span className="text-xs text-[#6D80A4]">
                  (+ ${comission} de comisión)
                </span>
              </p>
            </div>
            <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4  font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                <span className="font-bold">Referencia: </span>
              </p>
              <p className="text-[#6D80A4] laptop:text-xl phone:text-base pl-1">
                {reference}
              </p>
            </div>
            <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4  font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                <span className="font-bold laptop:text-xl phone:text-base">
                  Fecha límite de pago:{" "}
                </span>{" "}
              </p>
              <p>
                <span className="laptop:text-xl phone:text-base text-[#6D80A4] pl-1">
                  {formattedDate} hrs.
                </span>
              </p>
            </div>
          </div>
          <div className="pt-8 text-sm laptop:text-base laptop:pt-[2rem] laptop:pb-[1rem] text-center">
            <button
              onClick={handleContinueClick}
              className=" font-thin bg-orange-500 hover:bg-orng text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6 laptop:w-64 phone:w-48"
            >
              Aceptar
            </button>
          </div>
        </div>
      )}
    </section>
  );
}
