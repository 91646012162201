import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Banner from "../components/sharedComponents/Banner";
import Spinner from "../components/sharedComponents/Spinner";
import Auth from "../utils/auth";
export default function CashPaymentSicatel() {
  const location = useLocation();
  const { amount, cleanId } = location.state;
  const [fzaVta, setFzaVta] = useState("");
  const titular = localStorage.getItem("cash_titular");
  const cashAmount = localStorage.getItem("cash_amount");
  const reference = localStorage.getItem("cash_id");
  const email = localStorage.getItem("email");
  let barCode = localStorage.getItem("barcode") || "false";
  const navigate = useNavigate();
  const postalCode = localStorage.getItem("postalCode") || "";
  const [isLoading, setIsLoading] = useState(true); 
  let isDist = localStorage.getItem("mtd");
  const mtd = localStorage.getItem("mtd");
  const mtdObj = mtd ? JSON.parse(mtd) : null;
  const isDistributor = mtdObj && Object.keys(mtdObj).length > 0;
  const reg = mtdObj?.region;

  useEffect(() => {
    const Fza = JSON.parse(localStorage.getItem("mtd"));
    const FzaDinamico = Fza.fzaVtaPadre;
    setFzaVta(FzaDinamico);
    if (!Auth.loggedIn()) {
      navigate("/home");
      return;
    }
  }, []);

  const handleContinueClick = async (e) => {
    e.preventDefault();
    if (isDist) {
      localStorage.clear();
      window.location.href =
        "https://www.distribuidor.telcel.com:4475/Portal-Distribuidores/app/login";
    } else {
      navigate("/home");
    }
  };
  useEffect(() => {
    if (!Auth.loggedIn()) {
      navigate("/home");
      return;
    }
    const timer = setTimeout(() => {
      setIsLoading(false); 
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
  window.dlMetri.push({
    event : "pageLoad",
    pagina: {
      canal : "Proteccion Movil Amigo Kit",
      seccion : "Pago",
      pageName : "Pago|Pago en Ventanilla"
    },
    usuario : {
      CP : postalCode ? postalCode : "",
      region : reg ? reg : "",
      perfil : isDistributor ? "Distribuidor" : "Usuario"
    }
  })
  console.log('dlMetri /pago en ventanilla', window.dlMetri);
  window.dlMetri.push({
    event: "transaccionExitosa",
    pagina: {
      canal: "Proteccion Movil Amigo Kit",
      seccion: "Contratacion en proceso",
      pageName: "Contratacion en proceso",
    },
    usuario: {
      CP: postalCode ? postalCode : "",
      region: reg ? reg : "",
      perfil: isDistributor ? "Distribuidor" : "Usuario",
    },
    productos: [
      {
        categoria: "Servicios",
        subcategoria: "Seguridad",
        nombre: "Proteccion Movil Amigo Kit",
        monto: amount,
      },
    ],
    transaccion: {
      id: cleanId,
      metPago: "Efectivo distribuidor",
      variante: "Distribuidor Autorizado",
      tipoTarjeta: "",
    },
  });
  console.log("dlMetri Sicatel", window.window.dlMetri);
}, []);
  return (
    <section className="font-gotham min-h-screen place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8 ">
      {isDist ? (
        <p className="absolute bg-primary text-white px-3 py-1 top-0 right-0 rounded-bl-lg">
          {fzaVta}
        </p>
      ) : null}
      <Banner />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="flex flex-col  justify-center item-center phone:row-span-4  laptop:col-span-6 py-10 ">
          <h1 className="text-2xl   text-primary font-semibold phone:px-8 text-center mb-8">
            Contratación en proceso
          </h1>
          <p className="text-xl texto-barras text-[#6D80A4] phone:px-8 laptop:w-[50vw] text-center text-base/5 mb-3">
            Los datos se registraron con éxito. <br />
            Indica a tu cliente que debe realizar el pago en caja para <br />
            finalizar la contratación del servicio.
          </p>
          <div className="flex flex-col justify-center laptop:items-start phone:items-start codigo-barras">
            <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-1 font-thin px-6 phone:px-9 ">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                <span className="font-bold">Titular: </span>
              </p>
              <p className="text-[#6D80A4] laptop:text-xl phone:text-base pl-1">
                {" "}
                {titular}
              </p>
            </div>
            <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4 font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                <span className="font-bold"> Correo electrónico: </span>
              </p>
              <p className="text-[#6D80A4] laptop:text-xl phone:text-base pl-1">
                {email}
              </p>
            </div>
            <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4 font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-bold">
                {" "}
                <span>Monto a pagar: </span>{" "}
              </p>
              <p className="pl-1 text-[#6D80A4] laptop:text-xl phone:text-base ">
                {"   "}${cashAmount}{" "}
              </p>
            </div>
            <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4  font-thin px-6 phone:px-9">
              <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                <span className="font-bold">Referencia: </span>
              </p>
              <p className="text-[#6D80A4] laptop:text-xl phone:text-base pl-1">
                {reference}
              </p>
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="text-center laptop:w-[50vw] phone:w-50  phoneS:w-100 tablet:w-100 transform scale-50"
            dangerouslySetInnerHTML={{ __html: barCode }}
          />
          <p className="text-center laptop:w-[50vw] mt-[-20px]">{reference}</p>
          
          <div className="pt-8 text-sm laptop:text-base laptop:pt-[2rem] laptop:pb-[1rem] text-center">
            <button
              onClick={handleContinueClick}
              className=" font-thin bg-orange-500 hover:bg-orng text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6 laptop:w-64 phone:w-48"
            >
              Finalizar
            </button>
          </div>
        </div>
      )}
    </section>
  );
}
