import React, { useState, useEffect } from "react";
import {useLocation,useNavigate } from "react-router-dom";
import CustomErrorModal from "./sharedComponents/CustomErrorModal";
import TransparentSpinner from "./sharedComponents/TransparentSpinner";
import { post, get } from "aws-amplify/api";
import candadocvv from "../assets/images/candadocvv.png";
import denegated from "../assets/images/denegated.png";
import warningLogo from "../assets/images/warningLogo.png";
import tcFallido from "../assets/images/tcFallido.png";
import MsiModal from "./sharedComponents/MsiModal";
import Visa from "../assets/images/Visa.png";
import MasterCard from "../assets/images/MasterCard.png";

export default function PaymentData() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [confirmCashPayment, setConfirmCashPayment] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [error, setError] = useState(false);
  const [errorImageIcon, setErrorImageIcon] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorParagraph, setErrorParagraph] = useState("");
  const [errorTextButton, setErrorTextButton] = useState("");
  const [sicatelPayment, setSicatelPayment] = useState(false);
  const [distributorPayment, setDistributorPayment] = useState(false);
  const [valor, setValor] = useState("");
  const [valor2, setValor2] = useState("");
  const [valor3, setValor3] = useState("");
  const [valor4, setValor4] = useState("");
  const [valor5, setValor5] = useState("");
  const [valor6, setValor6] = useState("");
  const [isCvvInvalid, setIsCvvInvalid] = useState(false);
  const [isTCDate, setIsTCDate] = useState(false);
  const [isCardNumberInvalid, setIsCardNumberInvalid] = useState(false);
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [isLastNameInvalid, setIsLastNameInvalid] = useState(false);
  const [isValidDate, setIsValidDate] = useState(false);
  const [numberOfMonths, setNumberOfMonths] = useState(1);
  const [cardDetails, setCardDetails] = useState({
    email: "",
    regionId: 1,
    accountNumber: 0,
    imei: localStorage.getItem("imei"),
    policyNumber: localStorage.getItem("policy"),
    expirationDate: "",
    cvv: "",
    name: "",
    lastName: "",
    postalCode: "",
  });
  const [incompleteCardDetails, setIncompleteCardDetails] = useState();
  const dataCompletedUser = JSON.parse(
    localStorage.getItem("userDataComplete") || "false"
  );
  const mtd = localStorage.getItem("mtd");
  const mtdObj = mtd ? JSON.parse(mtd) : null;
  const isDistributor = mtdObj && Object.keys(mtdObj).length > 0;
  const reg = mtdObj?.region;
  const postalCode = localStorage.getItem("postalCode") || "";
  const [isValidMsi, setIsValidMsi] = useState(false);
  const [isPriceValide, setIsPriceValide] = useState(false);
  const [triggerWarningError, setTriggerWarningError] = useState(false);
  const [isVisa, setIsVisa] = useState(false);
  const [isMasterCard, setIsMasterCard] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isMsiOpen, setIsMsiOpen] = useState(false);
  const location = useLocation();
  const isPrice = location.state?.isPrice;
  // const isPrice = 1000;
  console.log("Price", isPrice); 
  console.log("Numero de meses", numberOfMonths);
  console.log("isValidPrice", isPriceValide);
  useEffect(() => {
  if (isPrice >= 900 && isPrice < 2500){
    setIsPriceValide(true);
  }
}, []);


  const handleCardPaymentInputChange = (e) => {
    const { name, value } = e.target;
    const regexLetras = /[^a-zA-ZñÑ\s]/g;
    const regexNumeros = /^[0-9\s]*$/;
    const regexFecha = /^[0-9/]*$/;
    if (name === "name" || name === "lastName") {
      const newValue = value.replace(regexLetras, "");
      if (name === "name") {
        setValor(newValue);
      } else if (name === "lastName") {
        setValor2(newValue);
      }
    } else if (
      name === "accountNumber" ||
      name === "cvv" ||
      name === "expirationDate"
    ) {
      if (
        (name === "expirationDate" && regexFecha.test(value)) || regexNumeros.test(value)) {
        if (name === "accountNumber") {
          setValor3(value);
          const rawValue = value.replace(/\s/g, '');
          if (rawValue.length < 8) {
            setIsValidMsi(false);
            setIsVisa(false);
            setIsMasterCard(false);
          }
          console.log("accountNumber", rawValue);
          if (isPrice >= 900 && isPrice < 2500){
            if (rawValue.length == 8 || rawValue.length == 16) {
              const enteredBin = rawValue.slice(0, 8);
              obtencionMsi(enteredBin);   
            } 
          } else{
            if (rawValue.length == 8 || rawValue.length == 16) {
              const enteredBin = rawValue.slice(0, 8);
              obtencionSinMsi(enteredBin);   
            }
          }

          const formattedValue = rawValue.replace(/(\d{4})/g, '$1 ').trim();
          setInputValue(formattedValue);
        } else if (name === "cvv") {
          setValor5(value);
        } else if (name === "expirationDate") {
          let formattedValue = '';

          const numericValue = value.replace(/\D/g, '');
          
          if (value.length > 0) {
            formattedValue += numericValue.substring(0, 2);
          }

          if (value.length > 2) {
            formattedValue += '/' + numericValue.substring(2, 4);
          }

          setValor4(formattedValue);

          const [expMonth, expYear] = value
            .split("/")
            .map((num) => parseInt(num, 10));  

          

          const currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth() + 1;
          const currentYearShort = currentYear % 100; 
          if (
            value.length === 5 &&
            !isNaN(expMonth) &&
            !isNaN(expYear) &&
            expMonth >= 1 &&
            expMonth <= 12 &&
            expYear >= 0 &&
            expYear <= 99
          ) {
            validateDate(expYear, expMonth);
          }

          function validateDate(expYear, expMonth) {
            if (
              expYear > currentYearShort || 
              (expYear === currentYearShort && expMonth >= currentMonth)
            ) {
              setIsValidDate(true);
              setValor4(value);
              setIncompleteCardDetails("");
              console.log("Fecha válida");
            } else {
              setIsValidDate(false);
              setIncompleteCardDetails(
                "Tarjeta vencida, por favor, verifica la fecha de vencimiento"
              );
              console.log("Fecha de expiración inválida");
            }
          }
        }
      }
    } else if (
      name === "msiOptions"
    ) {
      setValor6(value);
      setNumberOfMonths(value)
    }
    setIsCvvInvalid(false);
    setIsTCDate(false);
    setIsCardNumberInvalid(false);
    setIsNameInvalid(false);
    setIsLastNameInvalid(false);
    setCardDetails({
      ...cardDetails,
      [e.target.name]: e.target.name === "accountNumber" ? e.target.value.replace(/\s/g, '') : e.target.value
    });
  };


  async function processCardPayment() {
    setIsLoading(true);
    const requestCreateCard = {
      account: Number(cardDetails.accountNumber),
      expiration: cardDetails.expirationDate, // MM/YY format
      cvv: Number(cardDetails.cvv),
      nombre: cardDetails.name + " " + cardDetails.lastName,
    };
    const jsonStr = JSON.stringify(requestCreateCard);
    console.log("jsonStr", jsonStr);
    const encodedBody = btoa(unescape(encodeURIComponent(jsonStr)));
    //const encodedBody = btoa(jsonStr);
    try {
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/create-card",
        options: {
          body: encodedBody,
        },
      });
      const { body } = await restOperation.response;
      const data = await body.json();
      console.log("data", data);
      if (data.code === "0") {
        try {
          const request_confirm_payment_data = {
            token: data.data.token,
            policyUuid: localStorage.getItem("policy"),
          };
          const jsonStr = JSON.stringify(request_confirm_payment_data);
          const encodedBody = btoa(jsonStr);
          const restOperation = post({
            apiName: "ppmrestapi",
            path: "/charge",
            options: {
              body: {
                encodedBody: encodedBody,
                msi: numberOfMonths
              }
            },
          });
          const { body } = await restOperation.response;
          const response = await body.json();
          if (response.code === "0") {
            localStorage.setItem("paymentConfirmed", true);
            localStorage.setItem(
              "titular",
              `${cardDetails.name} ${cardDetails.lastName}`
            );
            localStorage.setItem(
              "lastFourDigits",
              cardDetails.accountNumber.toString().slice(-4)
            );
            setIsLoading(true);
            navigate("/gracias-por-tu-compra", { 
              state: { response } 
            });          }
          if (response.code === "-1") {
            console.log("Redireccionando a 3DS", response);
            const tipoDeTarjeta = response.data.data.cargo.tarjeta.marca;
            let tipoDeTarjetaMarca =
              tipoDeTarjeta.charAt(0).toUpperCase() + tipoDeTarjeta.slice(1);
            const cleanId = response.data.data.cargo.id.replace(/-/g, "");
            window.dlMetri.push({
              event: "operacionErrorTRX",
              pagina: {
                canal: "Proteccion Movil Amigo Kit",
                seccion: "Formulario",
                pageName: "Error|Modal|Formulario|Redireccionando a 3DS",
              },
              usuario: {
                CP: postalCode ? postalCode : "",
                region: reg ? reg : "",
                perfil: isDistributor ? "Distribuidor" : "Usuario",
              },
              productos: [
                {
                  categoria: "Servicios",
                  subcategoria: "Seguridad",
                  nombre: "Proteccion Movil Amigo Kit",
                  monto: response.data.data.cargo.monto,
                },
              ],
              transaccion: {
                id: cleanId,
                metPago:
                  response.data.data.cargo.tarjeta.producto === "credit"
                    ? "Tarjeta de credito"
                    : "Tarjeta de debito",
                metPagoVariante: response.data.data.cargo.adquiriente,
                tipoTarjeta: tipoDeTarjetaMarca,
              },
              operacion: {
                nombre: "Pago no realizado",
                tipoRespuesta: "Error",
                variante: "Unico",
              },
              error: {
                tipo: "Error de sistema",
                mensaje: "Redireccionando a 3DS",
                codigoEstatus: "-1",
                mensajeBanca: "Tarjeta en status pendiente",
              },
            });
            console.log("dlMetri tarjeta", window.window.dlMetri);
            window.location.href = response.data.data.cargo.redireccion.url;
          }
        } catch (e) {
          console.log("POST call failed: ", JSON.parse(e.response.body));
          const errorBody = JSON.parse(e.response.body);
          console.log("Error body: ", errorBody.message);
          window.dlMetri.push({
            event: "operacionErrorTRX",
            pagina: {
              canal: "Proteccion Movil Amigo Kit",
              seccion: "Formulario",
              pageName: "Error|Modal|Formulario|Formas de pago|Error en parametros",
            },
            usuario: {
              CP: postalCode ? postalCode : "",
              region: reg ? reg : "",
              perfil: isDistributor ? "Distribuidor" : "Usuario",
            },
            productos: [
              {
                categoria: "Servicios",
                subcategoria: "Seguridad",
                nombre: "Proteccion Movil Amigo Kit",
                monto: "10",
              },
            ],
            transaccion: {
              id: "",
              metPago: "",
              metPagoVariante: "",
              tipoTarjeta: "",
            },
            operacion: {
              nombre: "Pago no realizado",
              tipoRespuesta: "Error",
              variante: "Unico",
            },
            error: {
              tipo: "Error de servicio",
              mensaje:"Lo sentimos, tu pago no pudo ser procesado. Por favor, intenta de nuevo o realiza el pago en efectivo.",
              codigoEstatus: "400",
              mensajeBanca: "Tarjeta sin fondos",
            },
          });
          console.log("dlMetri tarjeta", window.window.dlMetri);
          setError(true);
          setErrorImageIcon(tcFallido);
          setErrorTitle("Pago no realizado");
          setErrorParagraph("Lo sentimos, tu pago no pudo ser procesado. Por favor, intenta de nuevo o realiza el pago en efectivo.");
          setErrorTextButton("Intentar de nuevo");
          setIsLoading(false);
        }
        setIsLoading(false);
      }
    } catch (e) {
      console.log("POST token failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody);
      setError(true);
      setErrorImageIcon(tcFallido);
      setErrorTitle("Error en parámetros");
      console.log("code:", errorBody.code);
      if (errorBody.code === "412") {
        window.dlMetri.push({
          event: "operacionErrorTRX",
          pagina: {
            canal: "Proteccion Movil Amigo Kit",
            seccion: "Formulario",
            pageName: "Error|Modal|Formulario|Formas de Pago|Error en parametros",
          },
          usuario: {
            CP: postalCode ? postalCode : "",
            region: reg ? reg : "",
            perfil: isDistributor ? "Distribuidor" : "Usuario",
          },
          productos: [
            {
              categoria: "Servicios",
              subcategoria: "Seguridad",
              nombre: "Proteccion Movil Amigo Kit",
              monto: "",
            },
          ],
          operacion: {
            nombre: "Pago no realizado",
            tipoRespuesta: "Error",
            variante: "cvv",
          },
          error: {
            tipo: "Error de Usuario",
            mensaje: "La longitud del cvv de la tarjeta no es valida",
            codigoEstatus: errorBody.code,
            mensajeBanca: "Error de ccv",
          },
        });
        console.log("dlMetri tarjeta ccv", window.window.dlMetri);
      setErrorParagraph("La longitud del cvv de la tarjeta no es válida.");
      } else {
        window.dlMetri.push({
          event: "operacionErrorTRX",
          pagina: {
            canal: "Proteccion Movil Amigo Kit",
            seccion: "Formulario",
            pageName: "Error|Modal|Formulario|Formas de Pago|Error en parametros",
          },
          usuario: {
            CP: postalCode ? postalCode : "",
            region: reg ? reg : "",
            perfil: isDistributor ? "Distribuidor" : "Usuario",
          },
          productos: [
            {
              categoria: "Servicios",
              subcategoria: "Seguridad",
              nombre: "Proteccion Movil Amigo Kit",
              monto: "",
            },
          ],
          operacion: {
            nombre: "Pago no realizado",
            tipoRespuesta: "Error",
            variante: "Tarjeta",
          },
          error: {
            tipo: "Error de Usuario",
            mensaje: "Datos de tarjeta incorrectos, intente de nuevo",
            codigoEstatus: errorBody.code,
            mensajeBanca: "Error de tarjeta",
          },
        });
        console.log("dlMetri tarjeta error", window.window.dlMetri);
        setErrorParagraph("Datos de tarjeta incorrectos, intente de nuevo.");
      }
      setErrorTextButton("Intentar de nuevo");
      setIsLoading(false);
    }
  }
  async function processCashPayment() {
    console.log("getting proposal...");
    setIsLoading(true);
    const policy = localStorage.getItem("policy");
    try {
      const restOperation = get({
        apiName: "ppmrestapi",
        path: `/cashpayment/${policy}`,
      });
      const { body } = await restOperation.response;
      const data = await body.json();
      if (data.payment_status === "pending_payment") {
        const amount = data.amount; //amount": 114900,
        const newAmount = Math.floor(amount / 100); // newAmount will be 1149
        localStorage.setItem("cash_amount", newAmount);
        localStorage.setItem("cash_titular", data.customer_info.name);
        localStorage.setItem("cash_email", data.customer_info.email);
        localStorage.setItem("comission", data.comission);
        localStorage.setItem(
          "cash_id",
          data.charges.data[0].payment_method.reference
        );
        localStorage.setItem("expirationDate", data.expire_date);
        navigate("/pago-efectivo", { state: { data, newAmount } });
      }
    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody);
      if (errorBody.code === "030") {
        setError(true);
        setErrorImageIcon(tcFallido);
        setErrorTitle("Error en parámetros");
        setErrorParagraph(errorBody.message);
        setErrorTextButton("Intentar de nuevo");
        setIsLoading(false);
      }
    }
  }
  // PAGO EN VENTANILLA
  async function processSicatelCashBarcode() {
    console.log("getting proposal...");
    setIsLoading(true);
    const policy = localStorage.getItem("policy");
    try {
      const restOperation = get({
        apiName: "ppmrestapi",
        path: `/sicatel/${policy}`,
      });
      const { body } = await restOperation.response;
      const dataJson = await body.json();
      const data = dataJson.data;
      if (data.reference) {
        const amount = data.amount;
        localStorage.setItem("cash_amount", amount);
        localStorage.setItem("cash_titular", data.customer_info.name);
        localStorage.setItem("cash_email", data.customer_info.email);
        localStorage.setItem("cash_id", data.reference);
        localStorage.setItem("barcode", data.barcode);
        const cleanId = data.reference.replace(/-/g, "");
        navigate("/pago-ventanilla", { state: { amount, cleanId } });;
      }
    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody);
      if (errorBody.code === "030") {
        setError(true);
        setErrorImageIcon(tcFallido);
        setErrorTitle("Error en parámetros");
        setErrorParagraph(errorBody.message);
        setErrorTextButton("Intentar de nuevo");
        setIsLoading(false);
      }
    }
  }
  // PAGO EFECTIVO MTD
  async function processCashDistribuitor() {
    console.log("getting proposal...");
    setIsLoading(true);
    const policy = localStorage.getItem("policy");
    try {
      const restOperation = get({
        apiName: "ppmrestapi",
        path: `/cash-distributor/${policy}`,
      });
      const { body } = await restOperation.response;
      const dataJson = await body.json();
      const data = dataJson.data;
      if (data.reference) {
        const amount = data.amount;
        localStorage.setItem("cash_amount", amount);
        localStorage.setItem("cash_titular", data.customer_info.name);
        localStorage.setItem("cash_email", data.customer_info.email);
        localStorage.setItem("cash_imei", data.customer_info.imei);
        localStorage.setItem("cash_id", data.reference);
        const cleanId = data.reference.replace(/-/g, "");
        navigate("/comprobante-recepcion-pago", { state: { amount, cleanId } });
      }
    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody);
      if (errorBody.code === "030") {
        setError(true);
        setErrorImageIcon(tcFallido);
        setErrorTitle("Error en parámetros");
        setErrorParagraph(errorBody.message);
        setErrorTextButton("Intentar de nuevo");
        setIsLoading(false);
      }
    }
  }
  async function getPayments() {
    console.log("Getting payments...");
    setIsLoading(true);
    try {
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/payments/",
        path: `/payments`,
        options: {
          body: mtd,
        },
      });
      const { body } = await restOperation.response;
      const data = await body.json();
      if (data.code === "0") {
        try {
          setSicatelPayment(data.data.sicatel);
          setDistributorPayment(data.data.cashPayment);
        } catch (e) {
          const errorBody = JSON.parse(e.response.body);
          console.log("Faild evaluating payment methods: ", errorBody.message);
          setSicatelPayment(false);
          setDistributorPayment(false);
        }
      } else {
        setSicatelPayment(false);
        setDistributorPayment(false);
      }
      setIsLoading(false);
    } catch (e) {
      const errorBody = JSON.parse(e.response.body);
      console.log("Faild getting payment methods: ", errorBody.message);
      setSicatelPayment(false);
      setDistributorPayment(false);
      setIsLoading(false);
    }
  }
  const validation = (name, value) => {
    if (name === "date") {
      const datePattern = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
      return datePattern.test(value);
    } else if (name === "postalCode") {
      const postalCodePattern = /^[0-9]{5,6}$/;
      return postalCodePattern.test(value);
    } else if (name === "cardNumber") {
      const rawValue = (value || "").replace(/\s/g, '');
      const cardNumberPattern = /^[0-9]{14,19}$/;
      return cardNumberPattern.test(rawValue);
    } else if (name === "name" || name === "lastname") {
      const namePattern = /^[a-zA-ZñÑ]+(([',. -][a-zA-ZñÑ ])?[a-zA-ZñÑ]*)*$/;
      return namePattern.test(value);
    }
    // Add more validation checks for other fields here
  };
  const handleSubmit = (e) => {
    let isValid = true;
    e.preventDefault();
    if (!termsAndConditions) {
      return;
    }
    if (paymentMethod === "card") {
      console.log(paymentMethod);
      if (
        !cardDetails.cvv ||
        cardDetails.cvv.length < 3 ||
        cardDetails.cvv.length > 4
      ) {
        setIsCvvInvalid(true);
        isValid = false;
      }
      if (!validation("date", cardDetails.expirationDate)) {
        setIsTCDate(true);
        isValid = false;
      }
      if (!validation("cardNumber", cardDetails.accountNumber)) {
        setIsCardNumberInvalid(true);
        isValid = false;
      }
      if (!validation("name", cardDetails.name)) {
        setIsNameInvalid(true);
        isValid = false;
      }

      if (!validation("lastname", cardDetails.lastName)) {
        setIsLastNameInvalid(true);
        isValid = false;
      }
      if (!isValidDate && cardDetails.expirationDate) {
        setIncompleteCardDetails(
          "Tarjeta vencida, por favor, verifica la fecha de vencimiento"
        );
        return;
      }
      if (!isValid) {
        setIncompleteCardDetails(
          "Por favor, completa los datos de la tarjeta antes de continuar"
        );
        return;
      }
      if (dataCompletedUser && cardDetails.policyNumber !== "") {
        setIsLoading(true);
        processCardPayment().finally(() => setIsLoading(false));
      }
    } else if (paymentMethod === "oxxo") {
      console.log(paymentMethod);
      if (dataCompletedUser) {
        setIsLoading(true);
        processCashPayment().finally(() => setIsLoading(false));
      }
    } else if (paymentMethod === "sicatel") {
      console.log(paymentMethod);
      if (dataCompletedUser) {
        setIsLoading(true);
        processSicatelCashBarcode().finally(() => setIsLoading(false));
      }
    } else if (paymentMethod === "cashDistribuitor") {
      console.log(paymentMethod);
      if (dataCompletedUser) {
        setIsLoading(true);
        processCashDistribuitor().finally(() => setIsLoading(false));
      }
    }
  };
  const handlePaymentMethodChange = (event) => {
    if (event.target.value === "card") {
      setPaymentMethod(event.target.value);
      setConfirmCashPayment(true);
    } else if (event.target.value === "oxxo") {
      setPaymentMethod(event.target.value);
      setConfirmCashPayment(true);
      setIncompleteCardDetails();
    } else if (event.target.value === "sicatel") {
      setPaymentMethod(event.target.value);
      setConfirmCashPayment(false);
      setIncompleteCardDetails();
    } else if (event.target.value === "cashDistribuitor") {
      setPaymentMethod(event.target.value);
      setConfirmCashPayment(false);
      setIncompleteCardDetails();
    }
  };
  useEffect(() => {
    if (isDistributor) {
      getPayments();
    }
  }, []);

  async function obtencionMsi(enteredBin) {
    const bin = enteredBin;
    console.log("El bin a validar es", bin)
    try {
      console.log("bin",typeof bin);
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/bins",
        options: {
            body:{
              bin:bin,
              reg:reg
            }
        },
      }); 
      const { body } = await restOperation.response;
      const data = await body.json();
      console.log("data total", data);
      console.log("IsValid", data.isValid )
      console.log("Marca", data.brand)
      if (data.isValid) {
        setIsValidMsi(true);
      }
      else {
        setIsValidMsi(false);
      }
      if (data.brand === "VISA") {
        setIsVisa(true);
        setIsMasterCard(false);
      }else if (data.brand === "MASTER CARD") {
        setIsMasterCard(true);
        setIsVisa(false);
      } else{
        setIsVisa(false);
        setIsMasterCard(false);
      }

    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody.message
      );}
  }

  async function obtencionSinMsi(enteredBin) {
    const bin = enteredBin;
    console.log("El bin a validar es", bin)
    try {
      console.log("bin",typeof bin);
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/bins",
        options: {
            body:{
              bin:bin,
              reg:reg
            }
        },
      }); 
      const { body } = await restOperation.response;
      const data = await body.json();
      if (data.brand === "VISA") {
        setIsVisa(true);
        setIsMasterCard(false);
      }else if (data.brand === "MASTER CARD") {
        setIsMasterCard(true);
        setIsVisa(false);
      } else{
        setIsVisa(false);
        setIsMasterCard(false);
      }

    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody.message
      );}
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      const input = valor4.replace(/\D/g, ''); // Elimina todo lo que no sea un número
      let newInput = input.substring(0, input.length - 1); // Elimina el último dígito

      if (newInput.length > 2) {
        newInput = newInput.substring(0, 2) + '/' + newInput.substring(2);
      }

      setValor4(newInput);
    }
  };


  return (
    <div className="flex flex-col items-center justify-center my-2 text-primary">
      {isLoading ? (
        <TransparentSpinner />
      ) : (
        <form
          onSubmit={handleSubmit}
          className=" w-full mx-auto bg-[#fff7e9] rounded p-5"
        >
          <h1 className="text-[#6D80A4] text-lg mb-4">
            Elige como quieres realizar tu pago:
          </h1>
          <div className="flex mb-4 ">
            <input
              id="card"
              name="card"
              value="card"
              type="radio"
              checked={paymentMethod === "card"}
              onChange={handlePaymentMethodChange}
              className=" indeterminate:bg-gray-300 mr-2"
            />
            <label
              htmlFor="credit_payment"
              className="text-[0.8rem] text-[#6D80A4] font-bold"
            >
              Tarjeta de crédito o débito{" "}
              <span className="font-thin">| Visa, MasterCard</span>
            </label>
          </div>
          {paymentMethod === "card" ? (
            <>
            <div className="flex flex row">
            <div className="flex flex-col mb-3">
             <div className="flex flex-row mb-3">
              <div className="flex flex-col mb-2 mr-1 w-full ">
                <label className="text-[#6D80A4] pb-1 ">
                  Nombre del Titular
                </label>
                <input
                  type="text"
                  name="name"
                  value={valor}
                  onChange={handleCardPaymentInputChange}
                  className={`text-[#6D80A4] mb-4 mr-2 w-full px-3 py-2 placeholder-gray-300 border ${
                    isNameInvalid ? "border-red-500" : "border-gray-300"
                  } rounded-md focus:outline-none focus:border-indigo-500`}
                />
              </div>
              <div className="flex flex-col mb-2 mr-1 w-full ">
                  <label className="text-[#6D80A4] pb-1">Apellidos</label>
                  <input
                    type="text"
                    name="lastName"
                    value={valor2}
                    onChange={handleCardPaymentInputChange}
                    className={`text-[#6D80A4] mb-4 mr-2 w-full px-3 py-2 placeholder-gray-300 border ${
                      isLastNameInvalid ? "border-red-500" : "border-gray-300"
                    } rounded-md focus:outline-none focus:border-indigo-500`}
                  />
                </div>
              </div>



  <div className="flex flex-row mb-3">
  <div className="flex flex-col mr-1 w-full">
    <div className="flex justify-between items-center pb-1">
      <label className="text-[#6D80A4]">Número de tarjeta</label>
  {isPriceValide && !isValidMsi && (
  <p 
    onClick={() => setTriggerWarningError(true)} 
    className={`
      phone:hidden 
      laptop:block  
      phone:text-[0.7rem]
      text-blue-400 
      cursor-pointer
      ${isValidMsi ? "laptop:text-[0.6rem]" : "laptop:text-[0.9rem]"}
    `}
  >
    Bancos participantes MSI
  </p>
)}

    </div>
    <div className="relative w-full">
      {isVisa ? (
      <img 
        src={Visa} 
        alt="Visa Logo" 
        className="absolute left-3 top-5 transform -translate-y-1/2 h-3"
      />
      ) : null}
      {isMasterCard ? (
      <img 
        src={MasterCard} 
        alt="MasterCard Logo" 
        className="absolute left-4 top-5 transform -translate-y-3/4 h-3"
      />
      ) : null}
      <input
        type="text"
        maxLength={19}
        value={inputValue}
        name="accountNumber"
        onChange={handleCardPaymentInputChange}
        className={`text-[#6D80A4] mb-4 mr-2 w-full px-3 py-2 placeholder-gray-300 border ${
          isCardNumberInvalid ? "border-red-500" : "border-gray-300"
        } ${
          isVisa || isMasterCard ? " pl-14" : " pl-3"
        } rounded-md focus:outline-none focus:border-indigo-500`}
      />
    </div>
  </div>
  {isValidMsi ? (
        <div className="flex flex-col mr-1 w-full">
          <label className="text-[#6D80A4] pb-1 text-[13px]">Meses Sin Intereses</label>
          <select
            name="msiOptions"
            value={valor6}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500 text-gray-400"      
            onChange={handleCardPaymentInputChange}
            onClick={(e) => setNumberOfMonths(e.target.value)}
          >
            <option value="" disabled selected>
              Selecciona
            </option>
            <option value="1">1 mes</option>
            <option value="6">6 meses</option>
          </select>
        </div>
        ) : null} 
</div>
<div className="flex flex-row items-center">
<div className="flex flex-col mb-2 mr-1 max-w-[100%] mr-1">
  <label className="text-[#6D80A4] laptop:pb-1 phone:pb-0 phone:mb-3 laptop:mb-0 phone:text-xs laptop:text-sm">
    Fecha de vencimiento
  </label>
  <input
    type="text"
    name="expirationDate"
    maxLength={5}
    value={valor4}
    placeholder="mm/aa"
    onChange={handleCardPaymentInputChange}
    onKeyDown={handleKeyDown}
    className={`text-[#6D80A4] mb-4 mr-2 w-full px-3 py-2 placeholder-gray-300 border ${
      isTCDate ? "border-red-500" : "border-gray-300"
    } rounded-md focus:outline-none focus:border-indigo-500`}
  />
</div>

<div className="flex flex-col mb-2 mr-1 max-w-[50%]">
  <label className="text-[#6D80A4] laptop:pb-1 phone:pb-2 phone:mb-4 laptop:mb-0">
    CVV
  </label>
  <div className="relative">
    <input
      type="password"
      value={valor5}
      name="cvv"
      maxLength={4}
      onChange={handleCardPaymentInputChange}
      className={`text-[#6D80A4] mb-4 mr-2 w-full px-3 py-2 placeholder-gray-300 border ${
        isCvvInvalid ? "border-red-500" : "border-gray-300"
      } rounded-md focus:outline-none focus:border-indigo-500`}
    />
    <img
      src={candadocvv}
      className="absolute left-3 top-1/3 transform -translate-y-1/2 h-4 w-3"
    />
  </div>
</div>
</div>
</div>


<div className="flex flex-col items-center">
  
  <div className="flex flex-col mb-11" />
      {/* <div className="flex flex-col mb-2 mr-1 w-3/4 ">
      <label className="text-[#6D80A4] laptop:pb-1 phone:pb-2 phone:mb-4 laptop:mb-0">
        Código Postal
        <div className="relative inline-block ml-2">
          <span className="group flex items-center justify-center w-3 h-3 bg-[#6D80A4] text-white rounded-full cursor-pointer text-[0.5rem]">
            i
            <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-full bg-[#ffffff] text-black text-sm rounded-md px-3 py-2 opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
              Introduce el código postal de tu dirección de facturación.
            </div>
          </span>
        </div>
      </label>

      
        <div className="relative">
          <input
            name="postalCode"
            value={valor2}
            onChange={handleCardPaymentInputChange}
            maxLength={5}
            className={`text-[#6D80A4] mb-4 mr-2 w-full px-3 py-2 placeholder-gray-300 border ${
              isCvvInvalid ? "border-red-500" : "border-gray-300"
            } rounded-md focus:outline-none focus:border-indigo-500`}
          />
        </div>
      </div> */}
      </div>
      </div>
            </>
          ) : null}
          <div className="flex mb-4">
            <input
              id="oxxo"
              name="oxxo"
              value="oxxo"
              type="radio"
              checked={paymentMethod === "oxxo"}
              onChange={handlePaymentMethodChange}
              className=" indeterminate:bg-gray-300 mr-2"
            />
            <label
              htmlFor="credit_payment"
              className="text-[0.8rem] text-[#6D80A4] font-bold"
            >
              Efectivo <span className="font-thin">| OXXO</span>
            </label>
          </div>

          {sicatelPayment ? (
            <div className="flex mb-4">
              <input
                id="sicatel"
                name="sicatel"
                value="sicatel"
                type="radio"
                checked={paymentMethod === "sicatel"}
                onChange={handlePaymentMethodChange}
                className=" indeterminate:bg-gray-300 mr-2"
              />
              <label
                htmlFor="credit_payment"
                className="text-[0.8rem] text-[#6D80A4] font-bold"
              >
                Distribuidor Autorizado Telcel{" "}
                <span className="font-thin">| Efectivo</span>
              </label>
            </div>
          ) : null}

          {distributorPayment ? (
            <div className="flex mb-4">
              <input
                id="cashDistribuitor"
                name="cashDistribuitor"
                value="cashDistribuitor"
                type="radio"
                checked={paymentMethod === "cashDistribuitor"}
                onChange={handlePaymentMethodChange}
                className=" indeterminate:bg-gray-300 mr-2"
              />
              <label
                htmlFor="credit_payment"
                className="text-[0.8rem] text-[#6D80A4] font-bold"
              >
                Distribuidor Autorizado Telcel
                <span className="font-thin"> | Efectivo </span>
              </label>
            </div>
          ) : null}
          <div className="flex flex-col items-start justify-center">
            <div className="flex justify-start laptop:items-center phone:items-start mb-4 ">
              <input
                name="credit_payment"
                type="checkbox"
                checked={termsAndConditions}
                onClick={() => {
                  setTermsAndConditions(!termsAndConditions);
                }}
                className="indeterminate:bg-gray-300 mr-2 laptop:mt-0 phone:mt-[0.1rem]"
                readOnly
              />
              <label
                htmlFor="credit_payment"
                className="text-[13px]/[16px] text-[#6D80A4]"
              >
                He leído y estoy de acuerdo con los{" "}
                <span className="text-[#006BBC]">
                  <a
                    href="https://www.telcel.com/personas/servicios/localizacion-y-seguridad/proteccion-movil-amigo-kit"
                    target="_blank"
                  >
                    Términos y Condiciones.
                  </a>
                </span>{" "}
              </label>
            </div>
            {!termsAndConditions ? (
              <div className="flex flex-row items-center justify-center pt-1 phone:w-64 laptop:w-auto">
                <img src={warningLogo} alt="" className="w-4 h-4 mr-2" />
                <span className="w-96 text-red-500 text-[0.6rem] text-left">
                  Por favor acepta términos y condiciones para proceder con el
                  pago
                </span>
              </div>
            ) : null}
            {(paymentMethod === "cashDistribuitor" ||
              paymentMethod === "sicatel") && (
              <div className="flex justify-start laptop:items-center phone:items-start mb-5 ">
                <input
                  disabled={!dataCompletedUser}
                  name="credit_payment"
                  type="checkbox"
                  checked={confirmCashPayment}
                  onClick={() => {
                    setConfirmCashPayment(!confirmCashPayment);
                  }}
                  className="indeterminate:bg-gray-300 mr-2 laptop:mt-0 phone:mt-[0.1rem]"
                  readOnly
                />
                <label
                  htmlFor="credit_payment"
                  className="text-xs text-[#6D80A4]"
                >
                  Confirmo que gestionaré el pago de Protección Móvil Amigo Kit.
                </label>
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <button
              disabled={
                !dataCompletedUser || !termsAndConditions || !confirmCashPayment
              }
              type="submit"
              className={` ${
                !dataCompletedUser || !termsAndConditions || !confirmCashPayment
                  ? "bg-orange-300 hover:bg-orange-300 cursor-not-allowed"
                  : "bg-orng hover:bg-opacity-50 cursor-pointer"
              } laptop:w-1/3 phone:w-[28vw] px-3 py-2 text-white  rounded-md focus:outline-none"`}
            >
              Pagar
            </button>
          </div>
          {incompleteCardDetails && (
            <div className="text-center">
              <span className="text-red-500 text-xs">
                {incompleteCardDetails}
              </span>
            </div>
          )}
        </form>
      )}
      <MsiModal 
        isVisible={triggerWarningError} 
        setTriggerWarningError={setTriggerWarningError}
        onClose={() => setTriggerWarningError(false)} 
        onClickButton={() => setTriggerWarningError(false)}
        />
      <CustomErrorModal
        isVisible={error}
        onClose={() => setError(false)}
        onClickButton={() => setError(false)}
        imageIcon={errorImageIcon}
        title={errorTitle}
        paragraph={errorParagraph}
        textButton={errorTextButton}
      >
        {paymentMethod === "card" ? (
          <button
            onClick={() => {}}
            className="laptop:w-[10vw] phone:w-[50vw] tablet:w-[20vw] text-primary border border-primary border-1 bg-white hover:bg-gray-100
          focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
          rounded-md text-sm px-5 phone:px-1 laptop:py-2.5 phone:py-1 text-center phone:mr-2"
          >
            Pago en efectivo
          </button>
        ) : null}
      </CustomErrorModal>
    </div>
  );
}
