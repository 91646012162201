import React, { useState, useEffect } from "react";
import CustomErrorModal from "../components/sharedComponents/CustomErrorModal";
import { useNavigate } from "react-router-dom";
import Banner from "../components/sharedComponents/Banner";
import Spinner from "../components/sharedComponents/Spinner";
import { GoArrowLeft } from "react-icons/go";
import TermsModal from "../components/sharedComponents/TermsModal";
import ge from "../assets/images/ge.png";
import denegated from "../assets/images/denegated.png";
import { post, get } from "aws-amplify/api";
import Auth from "../utils/auth";

export default function Proposal() {
  let isDist = localStorage.getItem("mtd")
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [integrityConfirmation, setIntegrityConfirmation] = useState(false);
  const [insuranceCost, setInsuranceCost] = useState(0);
  const [brandModel, setBrandModel] = useState("");
  const [termsModal, setTermsModal] = useState(false);
  const [termsModalError, setTermsModalError] = useState(false);
  const [notFoundProposal, setNotFoundProposal] = useState(false);
  const [notFoundProposalMessage, setNotFoundProposalMessage] = useState("");
  const [isPrepay, setIsPrepay] = useState(false);
  const imei = localStorage.getItem("imei");
  const [fzaVta, setFzaVta] = useState("");
  const mtd = localStorage.getItem("mtd");
  const [isPrice, setIsPrice] = useState(0);
  const mtdObj = mtd ? JSON.parse(mtd) : null;
  const isDistributor = mtdObj && Object.keys(mtdObj).length > 0;
  const reg = mtdObj?.region;
  /**
   * Asynchronously fetches subscriber information based on the phone number and IMEI.
   *
   * This function sends a POST request to the "/insuranceinfo" endpoint with the phone number and IMEI.
   * It updates the global state and local storage with the subscriber's device brand, model, IMEI, and name details.
   * If an error occurs, it parses the error response.
   */
  async function getSubscriberInfo() {
    try {
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/insuranceinfo",
        options: {
          body: {
            phoneNumber: localStorage.getItem("phone"),
            imei: localStorage.getItem("imei"),
          },
        },
      });

      const { body } = await restOperation.response;  
      const response = await body.json();
      localStorage.setItem("brand", response.device_brand);
      localStorage.setItem("phoneModel", response.device_model);
      localStorage.setItem("subscriberIMEI", response.device_imei);
      localStorage.setItem("subscriberName", response.firstName);
      localStorage.setItem("subscriberFirstLastName", response.firstLastName);
      localStorage.setItem("subscriberSecondLastName", response.secondLastName);
    } catch (e) {
      const errorBody = JSON.parse(e.response.body);
    }
  }

  async function getPerfilamientoInfo() {
    try {
      const restOperation = post({
        apiName: "ppmrestapi",
        path: `/proposal/${imei}`,
        options: {
          body: {
            linea:"5554000818"
          },
        },
      });

      const { body } = await restOperation.response;  
      const response = await body.json();
      const perfil = response.perfilamiento.esValido;

      if (perfil === "NO") {
        setIsPrepay(true);
      }

    } catch (e) {
      const errorBody = JSON.parse(e.response.body);
    }
  }

  /**
   * Asynchronously fetches the proposal data based on the IMEI.
   *
   * This function sends a GET request to the `/proposal/${imei}` endpoint. If the response code is "0",
   * it sets the insurance cost and brand model in the state and local storage, retrieves subscriber info,
   * and dispatches an action to update the brand model in the global state. If an error occurs and the
   * error code is "1", it sets the not found proposal state and message.
   */
  async function getProposal() {
    try {
      const restOperation = get({
        apiName: "ppmrestapi",
        path: `/proposal/${imei}`,
      });

      const { body } = await restOperation.response;
      const data = await body.json();

      if (data.code === "0") {
        //setOtpSent(true);
        setInsuranceCost(data.data.proposal);
        localStorage.setItem("insuranceCost", data.data.proposal);
        const price = data.data.proposal;
        setIsPrice(price);
        setBrandModel(data.data.brandModel);
        localStorage.setItem("brandModel", data.data.brandModel);
      }
    } catch (e) {
      const errorBody = JSON.parse(e.response.body);
      if (errorBody.code === "1") {
        setNotFoundProposal(true);
        setNotFoundProposalMessage(errorBody.message);
      }
    }
  }

  const handleContinueClick = async (e) => {
    e.preventDefault();
    setTermsModal(true);
    window.dlMetri.push({
      event : "pageLoad",
      pagina: {
        canal : "Proteccion Movil Amigo Kit",
        seccion : "Cotizacion",
        pageName : "Modal|Cotizacion|Equipo en buen estado"
      },
      usuario : {
        CP : "",
        region : reg ? reg : "",
        perfil : isDistributor ? "Distribuidor" : "Usuario"
      }
    })
    console.log('dlMetri terminos y condiciones cotizacion', window.dlMetri);
  };

  /**
   * useEffect hook to handle authentication and fetch proposal data.
   *
   * This hook runs whenever the `insuranceCost` or `brandModel` values change.
   * - It checks if the user is logged in using `Auth.loggedIn()`. If not, it navigates to the "/home" route.
   * - It defines an asynchronous function `fetchData` to fetch the proposal data using `getProposal`.
   * - If an error occurs during the fetch, it logs the error to the console.
   * - Finally, it sets the loading state to false.
   */
  useEffect(() => {

     if(isDist){   
    const Fza = JSON.parse(localStorage.getItem("mtd"));
    const FzaDinamico = Fza.fzaVtaPadre ? Fza.fzaVtaPadre : "";
    setFzaVta(FzaDinamico);
    }

    if (!Auth.loggedIn()) {
      navigate("/home");
      return;
    } 
    const fetchData = async () => {
      try {
        await getProposal();
        // await getPerfilamientoInfo();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        await getSubscriberInfo();
      }
    };

    fetchData();
  }, [insuranceCost, brandModel]);
  const [fecha, setFecha] = useState(new Date());

  const formatearFecha = (fecha) => {
    const opciones = { year: 'numeric', month: 'long', day: 'numeric' };
    return fecha.toLocaleDateString('es-ES', opciones);
  };

  useEffect(() => {
    const intervalo = setInterval(() => {
      setFecha(new Date());
    }, 30000);

    return () => clearInterval(intervalo);
  }, []);
  useEffect(() => {
    window.dlMetri.push({
      event : "pageLoad",
      pagina: {
        canal : "Proteccion Movil Amigo Kit",
        seccion : "Cotizacion",
        pageName : "Cotizacion|Adquiere Proteccion Movil Amigo Kit"
      },
      usuario : {
        CP : "",
        region : reg ? reg : "",
        perfil : isDistributor ? "Distribuidor" : "Usuario"
      }
    })
    console.log('dlMetri /cotizacion', window.dlMetri);
  }, []);
  return (
    <section className="relative min-h-screen font-gotham place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      { isDist ?
      <p className="absolute bg-primary text-white px-3 py-1 top-0 right-0 rounded-bl-lg">
        {fzaVta}
      </p> : 
      null
      }
      <div className="absolute laptop:top-0 phone:top-[45vw] laptop:left-[26vw] phone:left-[2vw] laptop:pt-5 phone:pt-5 tablet:pt-8">
        <button
          onClick={() => {
            Auth.logout();
            navigate("/quiero-proteccionmovil");
          }}
        >
          <GoArrowLeft className="text-primary laptop:h-8 laptop:w-8 phone:h-6 phone:w-6" />
        </button>
      </div>

      <Banner />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="flex flex-col  phone:row-span-4  laptop:col-span-6 py-10 mx-8">
          <h1 className="laptop:text-2xl tablet:text-2xl phone:text-lg phone:text-lg text-primary font-semibold lg:py-6 text-center phone:py-5 laptop:py-0">
            Adquiere Protección Móvil Amigo Kit
          </h1>
          <div className="flex flex-col justify-start items-center text-left text-center text-center font-thin px-6 py-1">
            <p className="text-lg text-[#6d80A4]">para tu</p>
          </div>

          <h1 className="text-2xl md:text-3xl text-primary font-regular l:py-6 text-center">
            {brandModel}
          </h1>

          <div className="flex flex-col justify-start items-center text-left pb-4 text-center text-center font-thin px-6">
            <p className="text-lg text-[#6d80A4]">por solo</p>
          </div>

          <h1 className="laptop:text-6xl phone:text-4xl md:text-3xl text-primary font-semibold laptop:mb-8 phone:mb-4 text-center">
            ${insuranceCost}
          </h1>
          <div className="flex flex-row justify-around ">
            <div className="text-sm laptop:text-base laptop:py-[2rem] text-center phone:py-6">
              <button
                onClick={handleContinueClick}
                className="laptop:w-80 phone:w-48 text-center laptop:text-[1.1rem] phone:text-md laptop:py-3 laptop:px-12 phone:py-2 laptop:py-0 phone:px-8 shadow-md font-thin bg-orng hover:bg-orange-700 text-white font-bold rounded-md laptop:w-48 laptop:text-sm"
              >
                Continuar
              </button>
            </div>
          </div>
        <div className="flex flex-row justify-around " style={{fontSize: '14px', color: '#8d8d8d'}}> Precio valido solo hoy {formatearFecha(fecha)} </div>
        </div>
      )}
      <TermsModal
        isVisible={termsModal}
        onClose={() => setTermsModal(false)}
        color="warning"
      >
        <div className="flex flex-col justify-center py-5 px-5 laptop:px-7 text-left font-gotham">
          <div className="flex justify-center mb-4 mt-4">
            <img src={ge} alt="" className="text-center w-9 h-10" />
          </div>

          <div className="flex justify-center">
            <h1 className="text-[#6D80A4] text-lg font-semibold mb-2">
              Equipo en buen estado
            </h1>
          </div>
          <div className="w-auto rounded-full h-[0.3rem] bg-[rgba(255,193,7,0.25)] mb-3"></div>
          <p className="mb-4 text-[16.4px]/[24px] font-thin text-[#6D80A4] text-center">
            Para continuar con la contratación de Protección Móvil Amigo Kit,
            revisa y acepta los términos y condiciones del servicio.
          </p>
          <form
            className="space-y-6 flex flex-col justify-center items-center"
            action="#"
          >
            <div className="w-auto h-72 overflow-auto text-left phone:text-justify phone:text-xs laptop:text-[0.9rem]/[1.25rem] text-[#6D80A4]">
              Manifiesto bajo protesta de decir verdad que, el equipo celular
              que por este medio solicito inscribir en el programa Protección
              Móvil Amigo Kit de Telcel, incluyendo la pantalla del mismo, se
              encuentra en buen estado físico/funcional y que no tiene daños
              (incluyendo daños por líquido), cuarteaduras ni fallas eléctricas
              o mecánicas. Acepto y entiendo que en caso de que la declaración
              anterior sea falsa, Asurion México, S de R.L. de C.V. (“Asurion”)
              podrá dar por terminado mi Contrato de Adhesión para Servicios de
              Protección de Pantalla de Equipos Celulares (el “Contrato”),
              rechazará cualquier reclamo derivado del mismo y podrá iniciar
              acciones legales en mi contra. <br />
              <br />
              Por este medio acepto los términos y condiciones del Contrato, así
              como el tratamiento de mis datos conforme a lo establecido en los
              avisos de privacidad de Radiomóvil Dipsa, S.A. de C.V. (Telcel) y
              Asurion, los cuales se han puesto a mi disposición previamente y
              que puedo encontrar en{" "}
              <a
                href="https://www.telcel.com"
                target="_blank"
                className="text-[#006BBC]"
              >
                telcel.com
              </a>{" "}
              y{" "}
              <a
                href="https://www.telcel.com/proteccionamigokit"
                target="_blank"
                className="text-[#006BBC]"
              >
                telcel.com/proteccionamigokit
              </a>
              <br />
              {/* check terminos y condiciones */}
              <div className="w-auto flex justify-center">
                <div className="flex flex-row justify-center items-start laptop:mt-8 laptop:py-0 phone:py-8">
                  <input
                    name="credit_payment"
                    type="checkbox"
                    checked={integrityConfirmation}
                    onClick={() => {
                      setIntegrityConfirmation(!integrityConfirmation);
                    }}
                    className=" indeterminate:bg-gray-300 mr-2 determine:bg-primary cursor-pointer laptop:mt-[0.4rem] phone:mt-[0.1rem]"
                  />
                  <label
                    htmlFor="credit_payment"
                    className="laptop:text-base phone:text-xs text-left text-[#6D80A4]"
                  >
                    He leído y acepto los Términos y Condiciones del servicio.
                  </label>
                </div>
              </div>
            </div>
            <button
              type="button"
              disabled={!integrityConfirmation}
              onClick={() => {
                if (integrityConfirmation) {
                  setTermsModal(false);
                  navigate("/formulario",{ state: { isPrice } }); 
                } else setTermsModalError(true);
              }}
              className={`laptop:w-[10vw] phone:w-[50vw] tablet:w-[30vw] text-white bg-[#6D80A4] 
                    focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
                    rounded-lg text-sm px-5 phone:px-3 py-2.5 text-center
                    ${
                      integrityConfirmation
                        ? ""
                        : "cursor-not-allowed bg-opacity-35"
                    }`}
            >
              Aceptar
            </button>
          </form>
        </div>
      </TermsModal>
      <CustomErrorModal
        isVisible={notFoundProposal}
        onClose={() => setNotFoundProposalMessage(false)}
        onClickButton={() => {
          setNotFoundProposalMessage(false);
          navigate("https://www.distribuidor.telcel.com:4475/Portal-Distribuidores/app/login");
        }}
        imageIcon={denegated}
        title="Contratación no disponible"
        paragraph="No es posible contratar Protección Móvil Amigo Kit. Para más información, visita un Centro de Atención a Clientes."
        textButton="Aceptar"
      />
    </section>
  );
}
