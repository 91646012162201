import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../components/sharedComponents/Banner";
import CustomErrorModal from "../components/sharedComponents/CustomErrorModal";
import { GoArrowLeft } from "react-icons/go";
import Spinner from "../components/sharedComponents/Spinner";
import { post } from "aws-amplify/api";
import nocert from "../assets/images/nocert-15.png";
import Auth from "../utils/auth"; 
import warningLogo from "../assets/images/warningLogo.png";
export default function CertificateEntry() { 
  
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [notFoundErrorMessage, setNotFoundErrorMessage] = useState("");
  const [triggerError, setTriggerError] = useState(false);
  const [imeiState, setImeiState] = useState("");
  const mtd = localStorage.getItem("mtd");
  const mtdObj = mtd ? JSON.parse(mtd) : null;
  const isDistributor = mtdObj && Object.keys(mtdObj).length > 0;
  const reg = mtdObj?.region;
  const [emailFormState, setEmailFormState] = useState({
    phoneNumber: "",
    email: "",
  });
  const [focused, setFocused] = useState(false);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [imeiErrorMsg, setImeiErrorMsg] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [enableBtn, setEnableBtn] = useState(true);
  let isDist = localStorage.getItem("mtd")
  async function getCertificate() {
    try {
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/getinsurance",
        options: {
          body: {
            imei: imeiState ? imeiState : "",
            phone: emailFormState.phoneNumber ? emailFormState.phoneNumber : "",
            email: emailFormState.email ? emailFormState.email : "",
          },
        },
      });

      const { body } = await restOperation.response;
      const response = await body.json();
      if (response.code === "0") {
        if (imeiState && response.data.length == 0) {
          setNotFoundErrorMessage(
            "No se encontró un certificado para el IMEI ingresado. Por favor, verifícalo e intenta de nuevo"
          );
        } else if (emailFormState.phoneNumber && response.data.length == 0) {
          setNotFoundErrorMessage(
            "No se encontró un certificado para el número Telcel o correo electrónico ingresados. Por favor, verifícalos e intenta de nuevo"
          );
        }
        if (response.data.length > 1) {
          localStorage.setItem("email", emailFormState.email);
          localStorage.setItem("phone", emailFormState.phoneNumber);
          localStorage.setItem("certificates", JSON.stringify(response.data));
          Auth.severalCertificateLogin(response.token, response.data);
          // return navigate("/elige-certificado");
        } else if (response.data.length === 0) {
          return;
        } else {
          let serviceUrlObject = JSON.parse(response.data[0].serviceUrl.body);
          let serviceUrl = serviceUrlObject.data;

          localStorage.setItem("serviceName", response.data[0].model); 
          localStorage.setItem("email", response.data[0].email);
          localStorage.setItem("imei", response.data[0].imei);
          localStorage.setItem("downloadUrl", serviceUrl);
          Auth.uniqueCertificateLogin(response.token);
        }
      }
    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody);

      if (
        errorBody.code === "30" ||
        errorBody.code === "001" ||
        errorBody.code === "002" ||
        errorBody.code === "003"
      ) {
        imeiState
          ? setNotFoundErrorMessage(
              "No se encontró un certificado para el IMEI ingresado. Por favor, verifícalo e intenta de nuevo"
            )
          : setNotFoundErrorMessage(
              "No se encontró un certificado para el número Telcel o correo electrónico ingresados. Por favor, verifícalos e intenta de nuevo"
            );
        setTriggerError(true);
        if (imeiState) {
          window.dlMetri.push({
            event : "operacionErrorGN",
            pagina: {
              canal : "Proteccion Movil Amigo Kit",
              seccion : "Registro",
              pageName : `Registro|Error|Modal|No se encontro un certificado para el IMEI ingresado. Por favor, verificalo e intenta de nuevo`
            },
            usuario : {
              CP : "",
              region : "",
              perfil : ""
            },
            operacion : {
              nombre : "Certificado no disponible",
              tipoRespuesta : "Error",
              variante : "IMEI"
            },
            error : {
              tipo : "Error de Usuario",
              mensaje : "No se encontro un certificado para el IMEI ingresado. Por favor, verificalo e intenta de nuevo",
              codigoEstatus : errorBody.code,
            }
          })
          console.log('dlMetri IMEI sin Certificado', window.dlMetri);
        }
        else {
          window.dlMetri.push({
            event : "operacionErrorGN",
            pagina: {
              canal : "Proteccion Movil Amigo Kit",
              seccion : "Modal",
              pageName : `Error|Modal|No se encontro un certificado para el numero Telcel o correo electronico ingresados. Por favor, verificalos e intenta de nuevo`
            },
            usuario : {
              CP : "",
              region : "",
              perfil : ""
            },
            operacion : {
              nombre : "Certificado no disponible",
              tipoRespuesta : "Error",
              variante : "Numero y Correo"
            },
            error : {
              tipo : "Error de Usuario",
              mensaje : "No se encontro un certificado para el numero Telcel o correo electronico ingresados. Por favor, verifícalos e intenta de nuevo",
              codigoEstatus : errorBody.code,
            }
          })
          console.log('dlMetri Numero u correo sin Certificado', window.dlMetri);
        } 
      } else {
        navigate("/home");
      }
    }
  }
  const handleImeiChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value;
    if (name === "imei") {
      inputValue = inputValue.replace(/[^0-9]/g, '');
    }
    setImeiErrorMsg("");
    setPhoneErrorMsg("");
    setEmailErrorMsg("");
    setEmailFormState(
      Object.keys(emailFormState).reduce(
        (acc, key) => ({ ...acc, [key]: "" }),
        {}
      )
    );
    setImeiState(inputValue);
  };

  const handleNumberEmailChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value;

  if (name === "phoneNumber") {
    inputValue = inputValue.replace(/[^0-9]/g, '').slice(0, 10);
    setPhoneErrorMsg("");
  } else {
    setEmailErrorMsg("");
  }

    setImeiState("");
    setImeiErrorMsg("");
    name === "phoneNumber" ? setPhoneErrorMsg("") : setEmailErrorMsg("");
    setEmailFormState({ ...emailFormState, [name]: inputValue });

  };

  const validation = (name, value) => {
    let phoneRegex = /[0-9]{10}/;
    let imeiRegex = /[0-9]{15}/;
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (name === "phone") {
      return phoneRegex.test(value);
    } else if (name === "imei") {
      return imeiRegex.test(value);
    } else if (name === "email") {
      return emailRegex.test(value);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (
      imeiState !== "" ||
      (emailFormState.phoneNumber !== "" && emailFormState.email !== "")
    ) {
      if (imeiState !== "") {
        setIsLoading(true);
        validation("imei", imeiState)
          ? getCertificate().finally(() => setIsLoading(false))
          : setImeiErrorMsg("Porfavor ingresa un IMEI válido");
      } else if (
        emailFormState.phoneNumber !== "" &&
        emailFormState.email !== ""
      ) {
        if (!validation("phone", emailFormState.phoneNumber)) {
          console.log("phone error triggered");
          setPhoneErrorMsg("Porfavor ingresa un número válido");
        } else if (!validation("email", emailFormState.email)) {
          console.log("email error triggered");
          setEmailErrorMsg("Porfavor ingresa un correo válido");
        } else {
          setIsLoading(true);
          getCertificate().finally(() => setIsLoading(false));
        }
      }
    }
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber") {
      validation("phone", value)
        ? setPhoneErrorMsg("")
        : setPhoneErrorMsg("Por favor, proporciona un número válido.");
    } else if (name === "imei") {
      validation("imei", value)
        ? setImeiErrorMsg("")
        : setImeiErrorMsg("Por favor, proporciona un IMEI válido.");
    } else if (name === "email") {
      validation("email", value)
        ? setEmailErrorMsg("")
        : setEmailErrorMsg("Por favor, proporciona un correo válido.");
    }
    // setFormState({ ...formState, [name]: value });
  };

  useEffect(() => {
    if (
      validation("phone", emailFormState.phoneNumber) &&
      validation("email", emailFormState.email)
    ) {
      setEnableBtn(false);
    } else if (validation("imei", imeiState)) {
      setEnableBtn(false);
    } else {
      setEnableBtn(true);
    }
  }, [emailFormState.phoneNumber, emailFormState.email, imeiState]);

  useEffect(() => {
  window.dlMetri.push({
    event : "pageLoad",
    pagina: {
      canal : "Proteccion Movil Amigo Kit",
      seccion : "Ya tengo proteccion movil",
      pageName : "Ya tengo proteccion movil|Ingresa los siguientes datos para recibir tu certificado en el correo electronico que registraste al contratar el servicio"
    },
    usuario : {
      CP : "",
      region : reg ? reg : "",
      perfil : isDistributor ? "Distribuidor" : "Usuario"
    }
  })
  console.log('dlMetri /Certificado', window.dlMetri);
  }, []);
  
  return (
    <section className="relative font-gotham min-h-screen place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      <div className="absolute laptop:top-0 phone:top-[45vw] laptop:left-[26vw] phone:left-[2vw] laptop:pt-2 phone:pt-5 tablet:pt-8">
        <button
          onClick={() => {
            return navigate("/home");
          }}
        >
         {!isDist ?(
          <GoArrowLeft className="text-primary laptop:h-8 laptop:w-8 phone:h-6 phone:w-6" /> 
         ): null}
        </button>
      </div>
      <Banner />
      {isLoading ? (
        <Spinner />
      ) : (
        <form
          onSubmit={handleFormSubmit}
          className=" flex flex-col phone:row-span-4  laptop:col-span-6 laptop:py-20 phone:pt-10 laptop:px-[20vh] phone:px-[10vh] phone:py-[15vh] laptop:py-0"
        >
          <div className="flex justify-center">
            <h1 className="laptop:text-2xl tablet:text-2xl phone:text-lg text-primary text-center font-semibold  phone:py-4 phone:mb-1 laptop:py-0  laptop:px-0">
              Ingresa los siguientes datos para recibir tu certificado en el
              correo electrónico que registraste al contratar el servicio:
            </h1>
          </div>
          {/* IMEI handler section */}
          <div className="grid laptop:grid-cols-2 gap-4">
            <h1 className="laptop:py-10 phone:py-0 text-center laptop:px-[15vh] text-primary font-thin text-lg">
              Tengo mi IMEI
            </h1>
            <h1 className="phone:hidden laptop:block laptop:py-10 text-center laptop:px-[10vh] text-primary font-thin text-lg">
              No tengo mi IMEI
            </h1>
          </div>

          <div className="grid laptop:grid-cols-2 phone:grid-cols-1 gap-4">
            <div className="flex flex-col justify-start items-center">
              <div className="flex flex-col justify-start items-start text-left pt-10 laptop:mr-20  desktop:mr-20 margin1440">
                <div className="mb-2 flex flex-row justify-center items-center">
                  <div>
                  <label
                  htmlFor="name"
                  className="laptop:text-lg phone:text-md text-[#6D80A4] mb-2"
                >
                 IMEI
                 <p class="phoneS:text-[6.5px] phone:text-[6.5px] tablet:text-[6.7px] 
                laptop:text-[9px] desktop:text-[9px] laptop:inline 
                laptop:ml-2 custom-text-size1">(Para obtenerlo marca *#06# o encuéntralo en la caja de tu
                  smartphone.)</p>
                </label>
                  </div>
                  </div>

                <input
                  type="text"
                  id="imei"
                  name="imei"
                  placeholder="15 dígitos"
                  maxLength={15}
                  onChange={handleImeiChange}
                  onBlur={handleInputBlur}
                  value={imeiState}
                  className={`px-3 text-[#6D80A4] laptop:w-[29.7vw] desktop:w-[29.3vw] custom-width-laptopL custom-width-laptopXL custom-width-desktopL phone:w-56 h-10 border-2 ${
                    imeiErrorMsg ? "border-red-500" : "border-gray-300"
                  } rounded-md px-2`}
                />
                

                {imeiErrorMsg ? (
                  <div className="flex flex-row items-center justify-center pt-2">
                    <img src={warningLogo} alt="" className="w-4 h-4 mr-2" />
                    <span className="w-72 text-red-500 text-xs text-left">
                      {imeiErrorMsg}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>

            {/* // Divisor */}
            <div className="laptop:hidden border-b border-opacity-25 border-primary w-full mt-6"></div>
            <div className="absolute phone:hidden laptop:block border-l border-primary border-1 border-opacity-25 h-64 ml-[26.5vw]"></div>

            {/* Number and email handler section */}
            <div>
              <h1 className="laptop:hidden text-center laptop:py-10 phone:py-0 phone:pt-8 laptop:px-[10vh] text-primary font-thin text-lg">
                No tengo mi IMEI
              </h1>
              <div className="flex flex-col justify-center items-center">
                <div className="flex flex-col justify-start items-start text-left pt-10">
                  <label
                    htmlFor="name"
                    className="text-base text-[#6D80A4] mb-2"
                  >
                    Número Telcel
                  </label>
                  <input
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="10 dígitos"
                    maxLength={10} 
                    onChange={handleNumberEmailChange}
                    onBlur={handleInputBlur}
                    value={emailFormState.phoneNumber}
                    className={`px-3 text-[#6D80A4] laptop:w-[20vw] phone:w-56 h-10 border-2 pr-6 ${
                      phoneErrorMsg ? "border-red-500" : "border-gray-300"
                    } rounded-md px-2`}
                  />
                  {/* </div> */}
                  {phoneErrorMsg ? (
                    <div className="flex flex-row items-center justify-center pt-2">
                      <img src={warningLogo} alt="" className="w-4 h-4 mr-2" />
                      <span className="w-72 text-red-500 text-xs text-left">
                        {phoneErrorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div className="flex flex-col justify-start items-start text-left pt-10">
                  <label
                    htmlFor="name"
                    className="text-base text-[#6D80A4] mb-2"
                  >
                    Correo electrónico
                  </label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="ejemplo@mail.com"
                    onChange={handleNumberEmailChange}
                    onBlur={handleInputBlur}
                    value={emailFormState.email}
                    className={`px-3 text-[#6D80A4] laptop:w-[20vw] phone:w-56 h-10 border-2 pr-6 ${
                      emailErrorMsg ? "border-red-500" : "border-gray-300"
                    } rounded-md px-2`}
                  />
                  {/* </div> */}
                  {emailErrorMsg ? (
                    <div className="flex flex-row items-center justify-center pt-2">
                      <img src={warningLogo} alt="" className="w-4 h-4 mr-2" />
                      <span className="w-72 text-red-500 text-xs text-left">
                        {emailErrorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              disabled={enableBtn}
              onClick={() => setNotFoundErrorMessage("")}
              className={`w-[50vw] ${
                enableBtn
                  ? "bg-orange-300 cursor-not-allowed"
                  : "bg-orng hover:bg-orange-700 "
              } laptop:w-[20vw] mt-12 laptop:mt-20 text-white 
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md px-5 py-2.5 text-center`}
            >
              Aceptar
            </button>
          </div>
        </form>
      )}
      <CustomErrorModal
        isVisible={notFoundErrorMessage}
        onClose={() => setNotFoundErrorMessage("false")}
        onClickButton={() => {
          setTriggerError(false);
          if(isDist){
            localStorage.clear();
            window.location.href = 'https://www.distribuidor.telcel.com:4475/Portal-Distribuidores/app/login'
          } else{
          navigate("/home");
        }}}
        imageIcon={nocert}
        title="Certificado no disponible"
        paragraph="No se encontró un certificado para el IMEI ingresado. Por favor, verifícalo e intenta de nuevo."
        textButton="Aceptar"
      />
    </section>
  );
}
