import React, { useEffect, useState } from 'react';
//import { Provider } from "react-redux";
import { Navigate } from "react-router-dom";
// import { setContext } from "@apollo/client/link/context";
// import {
//   ApolloClient,
//   InMemoryCache,
//   ApolloProvider,
//   createHttpLink,
// } from "@apollo/client";
import { Amplify } from "aws-amplify";
import amplifyconfig from "./amplifyconfiguration.json";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import InsuranceEntry from "./pages/InsuranceEntry";
import Verification from "./pages/Verification";
import PolicyException from "./pages/PolicyException";
import InsuranceConfirmation from "./pages/InsuranceConfirmation";
import InsuranceConfirmation3DS from "./pages/InsuranceConfirmation3DS";
import PaymentDeclined3DS from "./pages/PaymentDeclined3DS";
import CertificateConfirmation from "./pages/CertificateConfirmation";
import Proposal from "./pages/Proposal";
import Home from "./pages/Home";
import Footer from "./components/sharedComponents/Footer";
import InsuranceEntryTicketUpload from "./pages/InsuranceEntryTicketUpload";
import InsuranceData from "./pages/InsuranceData";
import DistributorEntry from "./pages/DistributorEntry";
import DistributorSelection from "./pages/DistributorSelection";
import CertificateEntry from "./pages/CertificateEntry";
import CashPaymentConfirmation from "./pages/CashPaymentConfirmation";
import CashPaymentSicatel from "./pages/CashPaymentSicatel";
import ReceiptOfPayment from "./pages/ReceiptOfPayment";
import CertificateSelection from "./pages/CertificateSelection";
import useMaintenanceStatus from './components/sharedComponents/MaintenanceHook';
import Maintenance from './pages/Maitanance';
Amplify.configure(amplifyconfig);
// const httpLink = createHttpLink({
//   uri: "/graphql",
//   cache: new InMemoryCache(),
// });

// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = localStorage.getItem("id_token");
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : "",
//     },
//   };
// });

// const client = new ApolloClient({
//   link: authLink.concat(httpLink),
//   cache: new InMemoryCache(),
// });

function App() {
  const isMaintenance = useMaintenanceStatus();
  const [routes, setRoutes] = useState([]);
  useEffect(() => {
  if (isMaintenance) {
    setRoutes(
      [
        {
          path: "/mantenimiento",
           element: <Maintenance />,
        },
        {
          path: "*",
          element: <Navigate to="/mantenimiento" />,
        },
      ]
    );
} 
else {
  setRoutes([
    {
      path: "/distributorSelection",
      element: <DistributorSelection />,
    },
    {
      path: "/quiero-proteccionmovil-distribuidor/:parameters",
      element: <DistributorEntry />,
    },
    {
      path: "/insuranceEntryTicketUpload",
      element: <InsuranceEntryTicketUpload />,
    },
    {
      path: "/certificado",
      element: <CertificateConfirmation />,
    },
    {
      path: "/elige-certificado",
      element: <CertificateSelection />,
    },
    {
      path: "/ya-tengo-proteccionmovil",
      element: <CertificateEntry />,
    },
    {
      path: "/pago-efectivo",
      element: <CashPaymentConfirmation />,
    },
    {
      path: "/pago-ventanilla",
      element: <CashPaymentSicatel />,
    },
    {
      path: "/comprobante-recepcion-pago",
      element: <ReceiptOfPayment />,
    },
    {
      path: "/gracias-por-tu-compra",
      element: <InsuranceConfirmation />,
    },
    {
      path: "/gracias-por-tu-compra-3ds",
      element: <InsuranceConfirmation3DS />,
    },
    {
      path: "/tarjeta-rechazada-3ds",
      element: <PaymentDeclined3DS />,
    },
    {
      path: "/formulario",
      element: <InsuranceData />,
    },
    {
      path: "/cotizacion",
      element: <Proposal />,
    },
    {
      path: "/acude-a-cac",
      element: <PolicyException />,
    },
    {
      path: "/codigo-seguridad",
      element: <Verification />,
    },
    {
      path: "/quiero-proteccionmovil",
      element: <InsuranceEntry />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "*",
      element: <Navigate to="/home" />,
    },
  ]) 
}
}, [isMaintenance]);

  return (
      <div className="App">
        <Router>
          <Routes>
            {routes.map((route, index) => {
              return (
                <Route key={index} path={route.path} element={route.element} />
              );
            })}
          </Routes>
        </Router>
        <Footer />
      </div>
  );
}

export default App;
