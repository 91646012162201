import React, { useEffect, useState } from "react";

const Accordion = ({ title, answer, bgColor, accordionOpen, setAccordionOpen, postalCode }) => {
  const mtd = localStorage.getItem("mtd");
  const mtdData = mtd ? JSON.parse(mtd) : "";
  const mtdObj = mtd ? JSON.parse(mtd) : null;
  const isDistributor = mtdObj && Object.keys(mtdObj).length > 0;
  const reg = mtdObj?.region;
  let section = ""
  switch (title) {
    case "Datos del equipo":
      section = "Datos del equipo"
      break;
    case "Datos del titular":
      section = "Datos del titular"
      break;
    case "Forma de pago":
      section = "Forma de pago"
      break;
    default:
  }
  useEffect(() => {
    if (accordionOpen) {
      window.window.dlMetri.push({
        event : "checkout",
        pagina: {
          canal : "Proteccion Movil Amigo Kit",
          seccion : "Formulario",
          pageName : `Formulario|${section}`
        },
        usuario : {
          CP : postalCode ? postalCode : "",
          region : reg ? reg : "",
          perfil : isDistributor ? "Distribuidor" : "Usuario"
        }
      })
      console.log('dlMetri /formulario', section, window.dlMetri);
    }
  },[accordionOpen])
  return (
    <div className="mb-2 shadow-md rounded-lg text-primary">
      <div className="flex items-center">
        <div
          className={`bg-${bgColor} w-[1rem] h-[4rem] rounded-tl-lg mr-2 mt-0`}
        ></div>
        <button
          onClick={() => setAccordionOpen(!accordionOpen)}
          className="flex justify-between items-center w-full mx-2"
        >
          <span className="font-bold text-lg">{title}</span>
          {/* {accordionOpen ? <span>-</span> : <span>+</span>} */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mt-1"
          >
            <path d="M6 9l6 6 6-6" />
          </svg>

          {/* <svg
            className="fill-primary shrink-0 ml-8"
            width="16"
            height="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              y="7"
              width="16"
              height="2"
              rx="1"
              className={`transform origin-center transition duration-200 ease-out ${
                accordionOpen && "!rotate-180"
              }`}
            />
            <rect
              y="7"
              width="16"
              height="2"
              rx="1"
              className={`transform origin-center rotate-90 transition duration-200 ease-out ${
                accordionOpen && "!rotate-180"
              }`}
            />
          </svg> */}
        </button>
      </div>

      <div
        className={`grid bg-[#fff7e9] transition-all duration-300 ease-in-out text-slate-600 text-sm ${
          accordionOpen
            ? "grid-rows-[1fr] opacity-100"
            : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div className="overflow-hidden">{answer}</div>
      </div>
    </div>
  );
};

export default Accordion;
