import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../components/sharedComponents/Banner";
import Spinner from "../components/sharedComponents/Spinner";
import PDF from "../assets/images/PDF.png";
import ZIP from "../assets/images/zip.png";
import Auth from "../utils/auth";
export default function CertificateConfirmation() {
  const navigate = useNavigate();
  const servicio = localStorage.getItem("serviceName");
  const email = localStorage.getItem("email");
  const imei = localStorage.getItem("imei");
  const phone = localStorage.getItem("phone");
  const [loading, setLoading] = useState(true);
  const isImei = imei ? true : false;
  const serviceName = localStorage.getItem("serviceName");

  const downloadUrl = localStorage.getItem("downloadUrl");
  const mtd = localStorage.getItem("mtd");
  const mtdObj = mtd ? JSON.parse(mtd) : null;
  const isDistributor = mtdObj && Object.keys(mtdObj).length > 0;
  const reg = mtdObj?.region;

  useEffect(() => {
    if (!Auth.loggedIn()) {
      navigate("/home");
      return;
    }
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    // Cleanup function to clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
  window.dlMetri.push({
    event : "operacionExitoGN",
    pagina: {
      canal : "Proteccion Movil Amigo Kit",
      seccion : "Envio exitoso",
      pageName : `Envio exitoso|Recibiras tu certificado por correo electronico.`
    },
    usuario : {
      CP : "",
      region : reg ? reg : "",
      perfil : isDistributor ? "Distribuidor" : "Usuario"
    },
    operacion : {
      nombre : "Certificado",
      tipoRespuesta : "Exito",
      variante : "Unica"
    }
  })
  console.log('dlMetri Certificado', window.dlMetri);
}, []);

  return (
    <section className="font-gotham min-h-screen place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      <Banner />
      {loading ? (
        <Spinner />
      ) : (
        <div className="flex flex-col justify-center items-center phone:row-span-4  laptop:col-span-6 py-8 ">
          <h1 className="text-2xl text-primary font-semibold lg:py-6 text-center laptop:pb-8 phone:pb-2">
            ¡Envío exitoso!
          </h1>

          <div className="flex flex-col justify-start items-center text-left pt-6 text-center font-thin px-6">
            <p className="text-xl text-[#6D80A4]">
              Recibirás tu certificado por correo electrónico.
            </p>
          </div>
          <div className="flex flex-col justify-center items-start">
            <div className="flex justify-start items-start text-left pt-4 text-center font-thin px-6 phone:px-9">
              <p className="text-lg text-[#6D80A4]">
                {isImei ? (
                  <div className="flex flex-col justify-center laptop:items-start phone:items-center">
                    <div className="pb-4">
                      <span className="font-semibold">IMEI:</span> {imei}
                    </div>
                    <div>
                      {" "}
                      <span className="font-semibold">
                        Correo electrónico:
                      </span>{" "}
                      {email}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col justify-center items-start laptop:text-xl phone:text-base  ">
                    <div className="pb-4 flex phone:flex-col laptop:flex-row phone:items-start">
                      <p>
                        <span className="font-semibold">Número Telcel: </span>{" "}
                      </p>

                      <p>{phone}</p>
                    </div>
                    <span className="font-semibold">Correo electrónico:</span>{" "}
                    {email}
                  </div>
                )}
              </p>
            </div>

            <div className="flex justify-center items-center  pt-4 text-center font-thin px-6 phone:px-9 mt-[1vw]">
              {serviceName ? (
                <>
                  <img src={PDF} alt="PDF" className="w-7 h-8 mr-2" />
                  <p className="text-xl laptop:text-2xl text-[#6D80A4] font-thin">
                    {servicio}
                  </p>
                </>
              ) : (
                <>
                  <img src={ZIP} alt="ZIP" className="w-7 h-8 mr-2" />
                  <p className="text-xl laptop:text-2xl text-[#6D80A4] font-thin">
                    Certificados Protección Móvil Amigo Kit
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-col justify-center items-center phone:pt-12 laptop:pt-6 ">
            <div className=" text-sm laptop:text-base laptop:pt-[2rem] laptop:pb-[1rem] text-center pb-4 laptop:w-64 phone:w-48">
              <button
                className=" font-thin bg-orng hover:bg-orange-300 text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6 laptop:w-64 phone:w-48"
              >
                <a
                  href={downloadUrl}
                  download
                  onClick={() => {
                    localStorage.removeItem("token");
                  }}
                  className=" font-thin bg-orng hover:bg-orange-300 text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6 laptop:w-64 phone:w-48"
                >
                  Descargar 
                </a>
              </button>
            </div>

            <div className="flex flex-col justify-center items-center text-center font-thin px-6 cursor-pointer phone:pt-3 w-96">
              <p className="text-xs text-[#6D80A4]">
                Para iniciar el proceso de reparación de tu pantalla comunícate
                al
                <span className="font-semibold"> 800 099 0803</span> o al{" "}
                <span className="font-semibold">*988</span> desde tu Telcel de
                lunes a viernes de 7:00 hrs. a 23:00 hrs. <br />o sábados y
                domingos de 7:00 hrs. a 22:00 hrs.
              </p>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
