import React, { useEffect, useState } from "react";
import Accordion from "./Accordion";
import SmartphoneData from "./SmartphoneData";
import UserData from "./UserData";
import PaymentData from "./PaymentData";
export default function InsuranceDataModal() {
  const [accordionOpen1, setAccordionOpen1] = useState(false);
  const [accordionOpen2, setAccordionOpen2] = useState(false);
  const [accordionOpen3, setAccordionOpen3] = useState(false);
  const [userComplete, setUserComplete] = useState(false);
  const [postalCode, setPostalCode] = useState(false);

  return (
    <div className="laptop:overflow-auto laptop:h-[40vw] p-4 rounded-lg">
      <Accordion
        title="Datos del equipo"
        answer={<SmartphoneData />}
        bgColor="orng"
        className="pt-2 border-b-4"
        accordionOpen={accordionOpen1}
        setAccordionOpen={setAccordionOpen1}
        postalCode={postalCode}
      />
      <Accordion
        title="Datos del titular"
        answer={<UserData accordionOpen={accordionOpen2} setAccordionOpen={setAccordionOpen2} setUserComplete={setUserComplete} setPostalCode={setPostalCode}/>}
        bgColor={userComplete ? "orng" : "[#fff7e9]"}
        className="pt-2 border-b-4"
        accordionOpen={accordionOpen2}
        setAccordionOpen={setAccordionOpen2}
        postalCode={postalCode}
      />
      <Accordion
        title="Forma de pago"
        answer={<PaymentData />}
        bgColor="[#fff7e9]"
        className="pt-2 border-b-4"
        accordionOpen={accordionOpen3}
        setAccordionOpen={setAccordionOpen3}
        postalCode={postalCode}
      />
    </div>
  );
}
