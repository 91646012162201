import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../components/sharedComponents/Banner";
import Spinner from "../components/sharedComponents/Spinner";
import { GoArrowLeft } from "react-icons/go";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { post } from "aws-amplify/api";
import Auth from "../utils/auth";

export default function CertificateSelection() {
  const cjson = localStorage.getItem("certificates");
  const certificates = JSON.parse(cjson);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [triggerError, setTriggerError] = useState(false);
  const [checkedPolicies, setCheckedPolicies] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedImeis, setSelectedImeis] = useState([]);
  const itemsPerPage = 5;
  const itemsperPagination = 5;

  const totalPages = Math.ceil(certificates.length / itemsPerPage);
  const totalGroups = Math.ceil(totalPages / itemsperPagination);

  const [currentGroup, setCurrentGroup] = useState(1);

  const startPage = (currentGroup - 1) * itemsperPagination + 1;
  const endPage = Math.min(startPage + itemsperPagination - 1, totalPages);
  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = certificates.slice(indexOfFirstItem, indexOfLastItem);
  console.log("Current Items", currentItems);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleGroupChange = (direction) => {
    setCurrentGroup((prevGroup) =>
      direction === "next"
        ? Math.min(prevGroup + 1, totalGroups)
        : Math.max(prevGroup - 1, 1)
    );
  };

  console.log(selectedImeis.length)

  async function sendCertificates() {
    console.log("validating");
    try {
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/certificates",
        options: {
          body: {
            imei: selectedImeis,
          },
        },
      });

      const { body } = await restOperation.response;
      const response = await body.json();
      console.log("Response", response);
      if (response.code === "0") {
        console.log("Imeis sent successfully");
        const downloadUrl = response.data;
        localStorage.setItem("downloadUrl", downloadUrl);
        window.dlMetri.push({
          event : "operacionExitoGN",
          pagina: {
            canal : "Proteccion Movil Amigo Kit",
            seccion : "Certificados encontrados",
            pageName : "Certificados encontrados|Seleccion"
          },
          usuario : {
            CP : "",
            region : "",
            perfil : ""
          },
          operacion : {
            nombre : "Envio certificado",
            tipoRespuesta : "Exito",
            variante : selectedImeis.length
          }
        })
        console.log('dlMetri envio de certificado', window.dlMetri);

        navigate("/certificado");
      }
    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      console.log("Error body: ", errorBody);
    }
  }
  useEffect(() => {
    if (!Auth.loggedIn()) {
      navigate("/home");
      return;
    }
    console.log("Certificates", certificates);
    const initialPoliciesState = certificates.reduce(
      (acc, policy) => ({ ...acc, [policy.device_brand_model]: false }), 
      {}
    );
    setCheckedPolicies(initialPoliciesState);
  }, []);

  const handleCheckboxChange = (policy) => {
    setCheckedPolicies((prevState) => {
      const newState = {
        ...prevState,
        [policy.imei]: !prevState[policy.imei],
      };
      // If the policy is being checked, add its imei to the selectedImeis array
      if (!prevState[policy.imei]) {
        setSelectedImeis((prevImeis) => {
          // Check if the policy's imei is already in the selectedImeis array
          if (prevImeis.includes(policy.imei)) {
            return prevImeis;
          }

          const newImeis = [...prevImeis, policy.imei];
          return newImeis;
        });
      } else {
        // If the policy is being unchecked, remove its imei from the selectedImeis array
        setSelectedImeis((prevImeis) => {
          const newImeis = prevImeis.filter((imei) => imei !== policy.imei);
          return newImeis;
        });
      }

      return newState;
    });
  };

  const handleSelectAllChange = () => {
    setSelectAll((prevState) => {
      const newState = !prevState;
      // If all policies are being checked, add all imeis to the selectedImeis array
      if (!prevState) {
        setSelectedImeis(certificates.map((policy) => policy.imei));
      } else {
        // If all policies are being unchecked, clear the selectedImeis array
        setSelectedImeis([]);
      }

      const newPoliciesState = certificates.reduce(
        (acc, policy) => ({ ...acc, [policy.imei]: newState }),
        {}
      );
      setCheckedPolicies(newPoliciesState);

      return newState;
    });
  };
  useEffect(() => {
    window.dlMetri.push({
      event : "pageLoad",
      pagina: {
        canal : "Proteccion Movil Amigo Kit",
        seccion : "Certificados encontrados",
        pageName : "Certificados encontrados|Elige el certificado que desees recibir por correo electronico"
      },
      usuario : {
        CP : "",
        region : "",
        perfil : "Usuario"
      }
    })

    console.log('dlMetri Seleccion Certificado', window.dlMetri);

  }, []);
  return (
    <section className="relative min-h-screen font-gotham place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      <div className="absolute laptop:top-0 phone:top-[45vw] laptop:left-[26vw] phone:left-[2vw] laptop:pt-5 phone:pt-5 tablet:pt-8">
        <button onClick={() => navigate("/ya-tengo-proteccionmovil")}>
          <GoArrowLeft className="text-primary laptop:h-8 laptop:w-8 phone:h-6 phone:w-6" />
        </button>
      </div>

      <Banner />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="flex flex-col  phone:row-span-4  laptop:col-span-6 py-10 phone:mx-3">
          <h1 className="laptop:text-2xl phone:text- text-primary font-semibold laptop:py-6 text-center">
            Certificados encontrados
          </h1>
          <p className="text-[#6D80A4]">
            Elige el certificado que desees recibir por correo electrónico:
          </p>
          {/* <InsuranceDataModal /> */}
          <div className="m-auto mt-6 flex-col laptop:w-[30vw] phone:w-[85vw] h-80 shadow-lg justify-start rounded-lg items-start">
            <div className="flex justify-evenly"></div>
            <div className="flex flex-row items-center justify-start mb-1 bg-[#EAC360] rounded-t-lg bg-opacity-20">
              <input
                name="credit_payment"
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAllChange}
                className="mx-5 my-4 indeterminate:bg-gray-300 mr-2"
              />
              <label
                htmlFor="credit_payment"
                className=" ml-2 text-sm text-[#6D80A4]"
              >
                Seleccionar todos
              </label>
              <div className="flex text-xs laptop:ml-[14vw] phone:ml-[30vw] text-[#6D80A4]">
                <p>{currentPage}</p>
                <span className="mx-2">|</span>

                <p className="opacity-75">{totalPages}</p>
              </div>
            </div>
            {currentItems.map((policy, index) => (
              <div className="flex flex-row items-center my-2" key={index}>
                <input
                  name={policy.imei}
                  type="checkbox"
                  checked={checkedPolicies[policy.imei] || false}
                  onChange={() => handleCheckboxChange(policy)}
                  className="mx-5 my-4 indeterminate:bg-gray-300 mr-2"
                />
                <label
                  htmlFor={policy.serviceName}
                  className="ml-2 text-sm text-[#6D80A4]"
                >
                  PMAK_{policy.model} 
                </label>{" "}
              </div>
            ))}
          </div>
          <div className="flex justify-end mr-8 mt-2">
            {" "}
            <button
              className={`nav-button ${currentGroup === 1 ? "disabled" : ""} ${
                totalPages <= 5 ? "hidden" : ""
              }`}
              disabled={currentGroup === 1}
              onClick={() => handleGroupChange("prev")}
            >
              <MdKeyboardArrowLeft />
            </button>
            {pageNumbers.map((number) => (
              <li
                className={`cursor-pointer list-none mr-2 ${
                  currentPage === number
                    ? "text-[#6D80A4] text-[0.9rem]"
                    : "text-gray-300"
                }`}
                key={number}
                onClick={() => handlePageChange(number)}
              >
                {number}
              </li>
            ))}
            <button
              className={`nav-button ${
                currentGroup === totalGroups ? "disabled" : ""
              } ${totalPages <= 5 ? "hidden" : ""}`}
              disabled={currentGroup === totalGroups}
              onClick={() => handleGroupChange("next")}
            >
              {" "}
              <MdOutlineKeyboardArrowRight />
            </button>
          </div>
          <div
            className="space-y-6 flex flex-row justify-center items-center pt-20"
            action="#"
          >
            <button
              onClick={() => {
                setTriggerError(false);
                console.log("Selected Imeis", selectedImeis);
                setIsLoading(true);
                sendCertificates().finally(() => setIsLoading(false));
              }}
              className="laptop:w-72 phone:w-[30vw] mt-6 text-white bg-orng hover:bg-primary 
              focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
              rounded-lg text-base px-5 phone:px-3 py-2.5 text-center"
            >
              Enviar
            </button>
          </div>
        </div>
      )}
    </section>
  );
}
