import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Banner from "../components/sharedComponents/Banner";
import Spinner from "../components/sharedComponents/Spinner";
import Auth from "../utils/auth";
import PdfGen from "../components/sharedComponents/PdfComprobante";
export default function ReceiptOfPayment() {
  const location = useLocation();
  const { amount, cleanId } = location.state;
  const pdfRef = useRef();
  const [isUser, setIsUser] = useState("");
  const [isImei, setIsImei] = useState("");
  const [isFzaVtaPadre, setIsFzaVtaPadre] = useState("");
  const [isFzaVtaHijo, setIsFzaVtaHijo] = useState("");

  const titular = localStorage.getItem("cash_titular");


// Nombre del cliente 
  const NameClient = localStorage.getItem("subscriberName");
  const FirstNameClient = localStorage.getItem("subscriberFirstLastName");
  const SecondNameClient = localStorage.getItem("subscriberSecondLastName");

  const FullName = `${NameClient} ${FirstNameClient} ${SecondNameClient}`;

  const cashAmount = localStorage.getItem("cash_amount");
  const email = localStorage.getItem("email");
  let barCode = localStorage.getItem("barcode") || "false";
  const navigate = useNavigate();
  const postalCode = localStorage.getItem("postalCode") || "";
  const [isLoading, setIsLoading] = useState(true);
  const mtd = localStorage.getItem("mtd");
  const mtdObj = mtd ? JSON.parse(mtd) : null;
  const isDistributor = mtdObj && Object.keys(mtdObj).length > 0;
  const reg = mtdObj?.region;
  useEffect(() => {
    if (!Auth.loggedIn()) {
      navigate("/home");
      return;
    }
    const timer = setTimeout(() => {
      setIsLoading(false); 
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  let isDist = localStorage.getItem("mtd")

  const generatePDF = async () => {
    if (pdfRef.current) {
      pdfRef.current.generatePDF();
      localStorage.clear();
    }
  };

  const handleDownloadPDF = async () => {
    await generatePDF();
    if (isDist) {
      window.location.href = 'https://www.distribuidor.telcel.com:4475/Portal-Distribuidores/app/login'
    } else {
      navigate("/home");
    }
  };



  useEffect(() => {
  const mtd = localStorage.getItem("mtd");
  const mtdObj =  mtd ? JSON.parse(mtd) : null;
  const user =  mtdObj?.user;
  setIsUser(user);
  const fzaVtaPadre = mtdObj?.fzaVtaPadre;
  setIsFzaVtaPadre(fzaVtaPadre);
  const fzaVtaHijo = mtdObj?.fzaVta;
  setIsFzaVtaHijo(fzaVtaHijo);
  const imei = localStorage.getItem("imei");
  setIsImei(imei);
  },[]);

  useEffect(() => {
  window.dlMetri.push({
    event : "pageLoad",
    pagina: {
      canal : "Proteccion Movil Amigo Kit",
      seccion : "Pago",
      pageName : "Pago|Pago en efectivo Distribuidor Autorizado"
    },
    usuario : {
      CP : postalCode ? postalCode : "",
      region : reg ? reg : "",
      perfil : isDistributor ? "Distribuidor" : "Usuario"
    }
  })
  console.log('dlMetri /efectivo', window.dlMetri);
  window.dlMetri.push({
    event: "transaccionExitosa",
    pagina: {
      canal: "Proteccion Movil Amigo Kit",
      seccion: "Recepcion de pago",
      pageName: "Recepcion de pago|Recepcion de pago en efectivo Distribuidor Autorizado",
    },
    usuario: {
      CP: postalCode ? postalCode : "",
      region: reg ? reg : "",
      perfil: isDistributor ? "Distribuidor" : "Usuario",
    },
    productos: [
      {
        categoria: "Servicios",
        subcategoria: "Seguridad",
        nombre: "Proteccion Movil Amigo Kit",
        monto: amount,
      },
    ],
    transaccion: {
      id: cleanId,
      metPago: "Efectivo distribuidor",
      variante: "Distribuidor Autorizado",
      tipoTarjeta: "",
    },
  });
  console.log("window.dlMetri Efectivo dist", window.window.dlMetri);
}, []);
  return (
    <section className="font-gotham min-h-screen place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
        { isDist ?
      <p className="absolute bg-primary text-white px-3 py-1 top-0 right-0 rounded-bl-lg">
        {isFzaVtaPadre}
      </p> : 
      null
      }
        <Banner />
      {isLoading ? (
        <Spinner />
      ) : (
        
          <div className="flex flex-col  justify-center item-center phone:row-span-4  laptop:col-span-6 py-10 ">
           
            <h1 className="text-2xl  text-primary font-semibold phone:px-8 text-center mb-8">
              Recepción de pago
            </h1>
            <div className="flex flex-col justify-center laptop:items-start phone:items-start">
              <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4 font-thin px-6 phone:px-9">
                <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                  <span className="font-bold">Titular: </span>
                </p>
                <p className="text-[#6D80A4] laptop:text-xl phone:text-base pl-1">
                  {" "}
                  {NameClient} {FirstNameClient} {SecondNameClient}
                </p>
              </div>
              <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4 font-thin px-6 phone:px-9">
                <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                  <span className="font-bold"> Correo electrónico: </span>
                </p>
                <p className="text-[#6D80A4] laptop:text-xl phone:text-base pl-1">
                  {email}
                </p>
              </div>
              <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4 font-thin px-6 phone:px-9">
                <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-bold">
                  {" "}
                  <span>Monto Pagado: </span>{" "}
                </p>
                <p className="pl-1 text-[#6D80A4] laptop:text-xl phone:text-base ">
                  {"   "}${cashAmount}{" "}
                </p>
              </div>
              <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4  font-thin px-6 phone:px-9">
                <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                  <span className="font-bold">IMEI: </span>
                </p>
                <p className="text-[#6D80A4] laptop:text-xl phone:text-base pl-1">
                  {isImei}
                </p>
              </div>
              <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4  font-thin px-6 phone:px-9">
                <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                  <span className="font-bold">Fuerza de Venta: </span>
                </p>
                <p className="text-[#6D80A4] laptop:text-xl phone:text-base pl-1">
                  {isFzaVtaPadre}
                </p>
                </div>
              <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4  font-thin px-6 phone:px-9">
                <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin">
                  <span className="font-bold">Usuario: </span>
                </p>
                <p className="text-[#6D80A4] laptop:text-xl phone:text-base pl-1">
                  {isUser}
                </p>
              </div>
              <div className="flex laptop:flex-row phone:flex-col justify-start items-start text-left pt-4  font-thin px-6 phone:px-9"></div>
            </div>
          
          <div className="pt-8 text-sm laptop:text-base laptop:pt-[2rem] laptop:pb-[1rem] text-center">
            {/* <button
              onClick={handleContinueClick}
              className=" font-thin bg-orange-500 hover:bg-orng text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6 laptop:w-64 phone:w-48"
            >
              Aceptar
            </button> */}
            {/* <p className="laptop:text-xl phone:text-base text-[#6D80A4] font-thin mb-2">Da Click para descargar tu comprobante de pago</p> */}
          
          <PdfGen 
            ref={pdfRef} 
            name={FullName} 
            email={email} 
            cash={cashAmount} 
            imei={isImei}
            FzaP={isFzaVtaPadre} 
            Fza={isFzaVtaHijo} 
            user={isUser} />
            <button
        onClick={handleDownloadPDF}
        className=" font-thin bg-orng hover:bg-orange-300 text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6 laptop:w-64 phone:w-48"
      >
        {/* <FaRegFilePdf  className="text-2xl"/> */}
        Descargar
        </button>
          </div>
        </div>
      )}
    </section>
  );
}
