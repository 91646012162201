import React, { Children } from "react";
import { PiCreditCard } from "react-icons/pi";
import Tarjeta from "../../assets/images/Tarjeta.png";

export default function MsiModal({ isVisible, onClose, setTriggerWarningError, children }) {
  if (!isVisible) return null;
  const handleClose = (e) => {
    if (e.target.id === "wrapper") onClose();
  };
  return (
    <div
      onClick={handleClose}
      className=" fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center"
      id="wrapper"
    >
      <div className="laptop:ml-[25vw] laptop:w-96 phone:w-80 flex flex-col">
        <div className="bg-white  rounded-md">
          <div className={`w-[auto] rounded-t-md h-[0.5rem] bg-[#EAC360]`} ></div>
          <div className="py-5 px-5 laptop:px-10 text-left">
            <div className="flex justify-center mb-4 mt-4">
              <img src={Tarjeta} className="text-center w-9 h-10 text-[#EAC360] text[px-10]"/>
            </div>
            <p className="text-center text-[#6D80A4] font-medium mb-2 text-[20px]">
              Meses sin intereses
            </p>
            <div className="w-auto rounded-full h-[0.3rem] bg-[rgba(255,193,7,0.25)] mb-3"></div>
  <p className="mb-4 text-[15px] font-regular text-[#6D80A4] text-center">
    6 Meses Sin Intereses a partir de $900 <br />
    y hasta $2,500 con tarjetas de crédito <br />
    de los siguientes bancos:
  </p>
  <div className="grid grid-cols-2 gap-x-8 mt-2 text-center text-[#6D80A4]">
    <div className="text-center">
      <p>Afirme</p>
      <p>BanBajío</p>
      {/* <p>Banorte*</p> */}
      <p>Banregio</p>
      <p>BBVA</p>
      <p>Bradescard</p>
      <p>CitiBanamex</p>
    </div>
    <div className="text-center">
      <p>HSBC</p>
      <p>Inbursa</p>
      <p>Invex</p>
      {/* <p>Liverpool</p> */}
      <p>Santander</p>
      <p>Scotiabank</p>
    </div>
  </div>
  {/* <p className="text-[11.5px] mt-3 text-[#6D80A4] mb-5">
    *La promoción de 6 Meses Sin Intereses con Banorte <br />
    solo aplica para equipos adquiridos en Ciudad de <br />
    México, Estado de México, Hidalgo y Morelos.
  </p> */}
            <div
              className="space-y-0 flex flex-row justify-center items-center"
              action="#"
            >
              <button
                onClick={() => {
                  setTriggerWarningError(false);
                }}
                className="mt-10 laptop:w-[11vw] phone:w-[40vw] text-white bg-[#6D80A4] hover:bg-opacity-85
                focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
                rounded-lg text-sm px-5 phone:px-1 laptop:py-2.5 phone:py-1 text-center"
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
