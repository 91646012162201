import React, { Children } from "react";
import { useNavigate } from "react-router-dom";

export default function WarningModal({ isVisible, onClose, color, protegido, triggerWarningErrorMessage, setTriggerWarningError, children }) {
  const navigate = useNavigate();
  if (!isVisible) return null;
  const handleClose = (e) => {
    if (e.target.id === "wrapper") onClose();
  };
  return (
    <div
      onClick={handleClose}
      className=" fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center"
      id="wrapper"
    >
      <div className="laptop:ml-[25vw] laptop:w-96 phone:w-80 flex flex-col">
        <div className="bg-white  rounded-md">
          <div className={`w-[auto] rounded-t-md h-[0.5rem] bg-[#EAC360]`} ></div>
          <div className="py-5 px-5 laptop:px-7 text-left">
            <div className="flex justify-center mb-4 mt-4">
              <img src={protegido} alt="" className="text-center w-9 h-10" />
            </div>
            <h1 className="text-center text-primary font-semibold mb-2">
              Equipo Protegido
            </h1>
            <div className="w-auto rounded-full h-[0.3rem] bg-[rgba(255,193,7,0.25)] mb-3"></div>
            <p className="mb-4 text-base font-regular text-[#6D80A4] text-center">
              {triggerWarningErrorMessage}
            </p>
            <div
              className="space-y-0 flex flex-row justify-center items-center"
              action="#"
            >
              <button
                onClick={() => {
                  setTriggerWarningError(false);
                }}
                className="laptop:w-[50vw] phone:w-[40vw] text-primary border border-primary border-1 bg-white hover:bg-gray-100 
                focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
                rounded-md text-sm px-5 phone:px-1 laptop:py-2.5 phone:py-1 text-center phone:mr-2"
              >
                Cerrar
              </button>
              <button
                onClick={() => {
                  setTriggerWarningError(false);
                  navigate("/ya-tengo-proteccionmovil");
                }}
                className="laptop:w-[50vw] phone:w-[40vw] text-white bg-[#6D80A4] hover:bg-opacity-85
                focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
                rounded-lg text-sm px-5 phone:px-1 laptop:py-2.5 phone:py-1 text-center"
              >
                Ver certificado
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
