import React, { useState, useLayoutEffect, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { post } from "aws-amplify/api";
import Auth from "../utils/auth";
import WarningModal from "./sharedComponents/WarningModal";
import CustomErrorModal from "./sharedComponents/CustomErrorModal";
import protegido from "../assets/images/protegido.png";
import denegated from "../assets/images/denegated.png";
import warningLogo from "../assets/images/warningLogo.png";
import Spinner from "./sharedComponents/Spinner";
import Banner from "./sharedComponents/Banner";
import { GoArrowLeft } from "react-icons/go";
import "../assets/css/style.css";

export default function Entry({ user }) {
  const navigate = useNavigate();
  const { parameters } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState({ phoneNumber: "", imei: "" });
  const [triggerError, setTriggerError] = useState(false);
  const [triggerWarningError, setTriggerWarningError] = useState(false);
  const [triggerWarningErrorMessage, setTriggerWarningErrorMessage] = useState("");
  const [triggerErrorMessage, setTriggerErrorMessage] = useState("");
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [imeiErrorMsg, setImeiErrorMsg] = useState("");
  const [enableBtn, setEnableBtn] = useState(false);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [fzaVta, setFzaVta] = useState("");
  const mtd = localStorage.getItem("mtd");
  const mtdObj = mtd ? JSON.parse(mtd) : null;
  const isDistributor = mtdObj && Object.keys(mtdObj).length > 0;
  const reg = mtdObj?.region;
  /**
   * Validates the input value based on the input field's name.
   *
   * This function uses regular expressions to validate phone numbers and IMEI numbers.
   * A valid phone number must be exactly 10 digits, and a valid IMEI number must be exactly 15 digits.
   */
  const validation = (name, value) => {
    let phoneRegex = /^[0-9]{10}$/;
    let imeiRegex = /^[0-9]{15}$/;
    if (name === "phone") {
      return phoneRegex.test(value);
    } else if (name === "imei") {
      return imeiRegex.test(value);
    }
  };
  /**
   * Handles the change event for input fields.
   *
   * This function updates the form state with the new value from the input field and clears any existing
   * error messages for the "phoneNumber" or "imei" fields.
   */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value;
    if (name === "phoneNumber") {
      inputValue = inputValue.replace(/[^0-9]/g, '');
      setPhoneErrorMsg("");
    } else if (name === "imei") {
      inputValue = inputValue.replace(/[^0-9]/g, '');
      setImeiErrorMsg("");
    }
    setFormState({ ...formState, [name]: inputValue });
  };
  /**
   * Handles the blur event for input fields.
   *
   * This function validates the input value based on the input field's name (either "phoneNumber" or "imei").
   * If the validation fails, it sets an appropriate error message; otherwise, it clears the error message.
   */
  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber") {
      validation("phone", value)
        ? setPhoneErrorMsg("")
        : setPhoneErrorMsg("Por favor, proporciona un número válido.");
    } else if (name === "imei") {
      validation("imei", value)
        ? setImeiErrorMsg("")
        : setImeiErrorMsg("Por favor, proporciona un IMEI válido.");
    }
  };
  /**
   * Asynchronously checks the IMEI by sending an OTP to the provided phone number.
   *
   * This function sends a POST request to the "/sendOTP" endpoint with the phone number and IMEI
   * from the form state. If the response code is "0", it updates the state and local storage with
   * the phone number and IMEI, sets the OTP sent flag to true, and logs in the user with the received token.
   * If an error occurs, it handles specific error codes by setting appropriate error messages and flags.
   */
  async function checkImei() {
    try {
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/sendOTP",
        options: {
          body: {
            phoneNumber: formState.phoneNumber,
            imei: formState.imei,
          },
        },
      });
      const { body } = await restOperation.response;
      const response = await body.json();
      if (response.code === "002" || response.code === "003") {
        //setPolicyException(true);
        //setTriggerErrorMessage(response.message);
      } else if (response.code === "001" || response.code === "005") {
        setTriggerError(true);
        setTriggerErrorMessage(response.message);
      } else if (response.code === "0") {
        localStorage.setItem("phone", formState.phoneNumber);
        localStorage.setItem("imei", formState.imei);
        localStorage.setItem("message", response.message);
        Auth.login(response.token);
      }
    } catch (e) {
      const errorBody = JSON.parse(e.response.body);
      if (
        errorBody.code === "001" ||
        errorBody.code === "002" ||
        errorBody.code === "003" ||
        errorBody.code === "006"
      ) {
        setTriggerError(true);
        setTriggerErrorMessage(errorBody.message);
        window.dlMetri.push({
          event : "operacionErrorGN",
          pagina: {
            canal : "Proteccion Movil Amigo Kit",
            seccion : "Quiero Proteccion Movil",
            pageName : "Error|Modal|Quiero Proteccion Movil|Ingresa los siguientes datos|IMEI no valido"
          },
          usuario : {
            CP : "",
            region : reg ? reg : "",
            perfil : isDistributor ? "Distribuidor" : "Usuario"
          },
          operacion : {
            nombre : "Contratacion no disponible",
            tipoRespuesta : "Error",
            variante : "Unico"
          },
          error : {
            tipo : "Error de servicio",
            mensaje : "Lo sentimos, no es posible contratar Proteccion Movil Amigo Kit despues de 30 dias de haber adquirido este smartphone.",
            codigoEstatus : errorBody.code
          }
        })
        console.log('dlMetri IMEI no válido', window.dlMetri);
      } else if (errorBody.code === "005" || errorBody.code === "007") {
        setTriggerWarningError(true);
        setTriggerWarningErrorMessage(errorBody.message);
        if (errorBody.message === "Estás cerca de contar con Protección Móvil Amigo Kit, realiza tu pago.") {
          window.dlMetri.push({
            event : "operacionErrorGN",
            pagina: {
              canal : "Proteccion Movil Amigo Kit",
              seccion : "Quiero Proteccion Movil",
              pageName : "Error|Modal|Quiero Proteccion Movil|Ingresa los siguientes datos|Realiza tu pago"
            },
            usuario : {
              CP : "",
              region : reg ? reg : "",
              perfil : isDistributor ? "Distribuidor" : "Usuario"
            },
            operacion : {
              nombre : "Realiza tu pago",
              tipoRespuesta : "Error",
              variante : "Unico"
            },
            error : {
              tipo : "Error de usuario",
              mensaje : "Estas cerca de contar con Proteccion Movil Amigo Kit, realiza tu pago.",
              codigoEstatus : errorBody.code
            }
          })
          console.log('dlMetri Realiza de pago', window.dlMetri);
        } else {
          window.dlMetri.push({
            event : "operacionErrorGN",
            pagina: {
              canal : "Proteccion Movil Amigo Kit",
              seccion : "Quiero Proteccion Movil",
              pageName : "Error|Modal|Quiero Proteccion Movil|Ingresa los siguientes datos|Equipo Protegido"
            },
            usuario : {
              CP : "",
              region : reg ? reg : "",
              perfil : isDistributor ? "Distribuidor" : "Usuario"
            },
            operacion : {
              nombre : "Equipo Protegido",
              tipoRespuesta : "Error",
              variante :"Unico"
            },
            error : {
              tipo : "Error de usuario",
              mensaje : "Tu smartphone ya cuenta con Proteccion Movil Amigo Kit.",
              codigoEstatus : errorBody.code
            }
          })
          console.log('dlMetri Equipo Protegido', window.dlMetri);
        }
      }
    }
  }
  /**
   * Handles the form submission event.
   *
   * This function prevents the default form submission behavior, validates the phone number and IMEI
   * from the form state, and sets appropriate error messages if the validation fails. If both fields
   * are valid, it sets the loading state to true, calls the `checkImei` function, and resets the loading
   * state once the `checkImei` function completes.
   */
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!formState.phoneNumber || !validation("phone", formState.phoneNumber)) {
      setPhoneErrorMsg("Por favor, proporciona un número válido.");
      return;
    } else if (!formState.imei || !validation("imei", formState.imei)) {
      setImeiErrorMsg("Por favor, proporciona un IMEI válido.");
      return;
    } else {
      setIsLoading(true);
      checkImei().finally(() => setIsLoading(false));
    }
  };
  /**
   * useEffect hook to enable or disable the submit button based on form validation.
   *
   * This hook runs whenever the `phoneNumber` or `imei` values in the `formState` change.
   * It validates both fields using the `validation` function. If both fields are valid,
   * it enables the submit button by setting `enableBtn` to false. Otherwise, it disables
   * the submit button by setting `enableBtn` to true.
   */
  useEffect(() => {
    if (
      validation("phone", formState.phoneNumber) &&
      validation("imei", formState.imei)
    ) {
      setEnableBtn(false);
    } else {
      setEnableBtn(true);
    }
  }, [formState.phoneNumber, formState.imei]);
  useLayoutEffect(() => {
    if (user === "Distributor") {
      validateDistributor().finally(() => setIsLoading(false));
    }
  }, []);
  async function validateDistributor() {
    console.log("validating");
    console.log("params: ", parameters);
    try {
      const restOperation = post({
        apiName: "ppmrestapi",
        path: "/distributorvalidation",
        options: {
          body: parameters,
        },
      });
      const { body } = await restOperation.response;
      const response = await body.json();
      const fzaVta = response.data.fzaVtaPadre;
      setFzaVta(fzaVta);
      if (response.code === "0") {
        setNotAuthorized(false);
        localStorage.setItem("mtd", JSON.stringify(response.data));
      }
    } catch (e) {
      console.log("POST call failed: ", JSON.parse(e.response.body));
      const errorBody = JSON.parse(e.response.body);
      setNotAuthorized(true);
      setEnableBtn(true);
    }
  }
  useEffect(() => {
    window.dlMetri.push({
      event : "pageLoad",
      pagina: {
        canal : "Proteccion Movil Amigo Kit",
        seccion : "Quiero Proteccion Movil",
        pageName : "Quiero Proteccion Movil|Ingresa los siguientes datos"
      },
      usuario : {
        CP : "",
        region : reg ? reg : "",
        perfil : isDistributor ? "Distribuidor" : "Usuario"
      }
    })
    console.log('dlMetri /quiero-proteccionmovil-distribuidor', window.dlMetri);
  }, []);
  const handleClick = () => {
    if (user === "Distributor") {
      window.location.href = 'https://www.distribuidor.telcel.com:4475/Portal-Distribuidores/app/login'
    } else {
      navigate("/home")
    }
  }
  return (
    <section className="font-gotham relative min-h-screen font-gotham place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      {user === "Distributor" ? <p className="absolute bg-primary text-white px-3 py-1 top-0 right-0 rounded-bl-lg">{fzaVta}</p> : null}
      <div className="absolute laptop:top-0 phone:top-[45vw] laptop:left-[26vw] phone:left-[2vw] tablet:pt-8 laptop:pt-5 phone:pt-5">
        {user !== "Distributor" ?
            <button
              onClick={() => {
                return navigate("/home");
              }}
            >
              <GoArrowLeft className="text-primary laptop:h-8 laptop:w-8 phone:h-6 phone:w-6" />
            </button>
            : null
        }
      </div>
      <Banner user={user}/>
      {isLoading ? (
        <Spinner />
      ) : (
        <form
          onSubmit={handleFormSubmit}
          className="flex flex-col phone:row-span-4 laptop:col-span-6 py-10"
        >
          <h1 className="laptop:text-2xl phone:text-xl text-primary text-center font-semibold lg:py-6">
            Ingresa los siguientes datos:
          </h1>
          <div className="flex flex-col justify-start items-start text-left pt-10">
            <label
              htmlFor="name"
              className="laptop:text-lg phone:text-md text-[#6D80A4] mb-2"
            >
              Número Telcel
            </label>
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="10 dígitos"
              maxLength={10}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              value={formState.phoneNumber}
              className={`text-[#6D80A4] laptop:w-[28vw] phone:w-[75vw] h-10 border-2 ${ phoneErrorMsg ? "border-red-500" : "border-[#6D80A4] border-opacity-50" } rounded-md px-2`}
            />
            {phoneErrorMsg ? (
              <div className="flex flex-row items-center justify-center pt-2">
                <img src={warningLogo} alt="" className="w-4 h-4 mr-2" />
                <span className="w-72 text-red-500 text-xs text-left">
                  {phoneErrorMsg}
                </span>
              </div>
            ) : null}
          </div>
          <div className="flex flex-col justify-start items-start text-left pt-10">
            <div className="mb-2 flex flex-row justify-center items-center">
              <div>
                <label
                  htmlFor="name"
                  className="laptop:text-lg phone:text-md text-[#6D80A4] mb-2"
                >
                  IMEI
                  <p className="text-[#6D80A4] phoneS:text-[6.5px] phone:text-[8px] laptop:text-[7.4px] desktop:text-[9px] tablet:text-[11px] custom-text-size laptop:inline laptop:ml-2">(Para obtenerlo marca *#06# o encuéntralo en la caja de tu smartphone.)</p>
                </label>
              </div>
            </div>
            <input
              type="text"
              id="imei"
              name="imei"
              placeholder="15 dígitos"
              maxLength={15}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              value={formState.imei}
              className={`text-[#6D80A4] laptop:w-[28vw] phone:w-[75vw] h-10 border-2 ${ imeiErrorMsg ? "border-red-500" : "border-[#6D80A4] border-opacity-50" } rounded-md px-2`}
            />
            {imeiErrorMsg ? (
              <div className="flex flex-row items-center justify-center pt-2">
                <img src={warningLogo} alt="" className="w-4 h-4 mr-2" />
                <span className="w-72 text-red-500 text-xs text-left">
                  {imeiErrorMsg}
                </span>
              </div>
            ) : null}
          </div>
          <div className="pt-8 text-sm laptop:text-[16.5px] laptop:py-[5rem] text-center">
            <button
              type="submit"
              disabled={enableBtn}
              className={`shadow-md font-thin ${
                enableBtn
                  ? "bg-orange-300 cursor-not-allowed"
                  : "bg-orng hover:bg-orange-700 "
              } text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6 laptop:w-64 phone:w-48`}
            >
              Continuar
            </button>
          </div>
        </form>
      )}
      { triggerWarningErrorMessage === "Estás cerca de contar con Protección Móvil Amigo Kit, realiza tu pago." ?
      <CustomErrorModal
        isVisible={triggerWarningError}
        onClose={() => setTriggerError(false)}
        onClickButton={() => {
          setTriggerWarningError(false);
        }}
        imageIcon={denegated}
        title="Realiza tu pago"
        paragraph="¡Estás cerca de activar tu Protección Móvil Amigo Kit!  Realiza tu pago cuanto antes. Consulta tu correo electrónico, ya que la referencia generada aún está vigente."
        textButton="Aceptar"
      />
          :
          <WarningModal
            isVisible={triggerWarningError}
            onClose={() => setTriggerWarningError(false)}
            color="warning"
            protegido={protegido}
            triggerWarningErrorMessage={triggerWarningErrorMessage}
            setTriggerWarningError={setTriggerWarningError}
          />
      }
      <CustomErrorModal
        isVisible={notAuthorized}
        onClose={() => {
          window.history.back();
          setNotAuthorized(false);
        }}
        onClickButton={() => {
          setNotAuthorized(false);
          window.history.back();
        }}
        imageIcon={denegated}
        title="Distribuidor no autorizado"
        paragraph="Lo sentimos, no estás autorizado para realizar esta operación."
        textButton="Aceptar"
      />
      <CustomErrorModal
        isVisible={triggerError}
        onClose={() => setTriggerError(false)}
        onClickButton={handleClick}
        imageIcon={denegated}
        title="Contratación no disponible"
        paragraph="Lo sentimos, no es posible contratar Protección Móvil Amigo Kit después de 30 días de haber adquirido este smartphone."
        textButton="Aceptar"
      />
    </section>
  );
}

