import React, { Children } from "react";

export default function CustomErrorModal({ isVisible, onClose, onClickButton, imageIcon, title, paragraph, textButton, children }) {
  if (!isVisible) return null;
  const handleClose = (e) => {
    if (e.target.id === "wrapper") onClose();
  };
  return (
    <div
      onClick={handleClose}
      className=" fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center"
      id="wrapper"
    >
      <div className="laptop:ml-[25vw] laptop:w-1/4 phone:w-80 flex flex-col">
        <div className="bg-white  rounded-md">
          <div className={`w-[auto] rounded-t-md h-[0.5rem] bg-[#F45753]`}></div>
          <div className="font-gotham flex flex-col items-center py-5 px-5 laptop:px-7 text-left">
            <div className="flex justify-center mb-4 mt-4">
              <img src={imageIcon} alt="" className="text-center w-10 h-10" />
            </div>
            <h1 className="text-center text-primary font-semibold mb-2">
              {title}
            </h1>
            <div className="laptop:w-96 phone:w-64 laptop:w-[20vw] rounded-full h-[0.3rem] bg-[#F45753] bg-opacity-25 mb-3"></div>
            <p className="mb-4 text-base font-regular text-[#6D80A4] text-center">
              {paragraph}
            </p>
            <div
              className="space-y-0 flex flex-row justify-center items-center"
              action="#"
            >
              {children}
              <button
                onClick={() => onClickButton()}
                className="laptop:w-[10vw] phone:w-[50vw] tablet:w-[20vw] text-white bg-[#6D80A4] hover:bg-opacity-75 
                focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium
                rounded-lg text-base px-5 phone:px-3 py-2 text-center"
              >
                {textButton}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
