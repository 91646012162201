import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Banner from "../components/sharedComponents/Banner";
import Spinner from "../components/sharedComponents/Spinner";
import Auth from "../utils/auth";
import Visa from "../assets/images/Visa.png";
import MasterCard from "../assets/images/MasterCard.png";

export default function InsuranceConfirmation() {
  const location = useLocation();
  const response = location.state?.response;
  console.log("response", response);
  const msi = response.data.data.cargo.PlanPagos.parcialidades;
  console.log("msi", msi);
  const cleanId = response.data.data.cargo.id.replace(/-/g, "");
  const tipoDeTarjeta = response.data.data.cargo.tarjeta.marca;
  let tipoDeTarjetaMarca =
    tipoDeTarjeta.charAt(0).toUpperCase() + tipoDeTarjeta.slice(1);
  console.log("TipoDeTarjeta", tipoDeTarjetaMarca);
  const titular = localStorage.getItem("titular");
  const lastFourDigits = localStorage.getItem("lastFourDigits");
  const insuranceCost = localStorage.getItem("insuranceCost");
  const email = localStorage.getItem("email");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // Initialize your variable
  const mtd = localStorage.getItem("mtd");
  const mtdObj = mtd ? JSON.parse(mtd) : null;
  const isDistributor = mtdObj && Object.keys(mtdObj).length > 0;
  const reg = mtdObj?.region;
  const [isMsi, setIsMsi] = useState(false);
  const [isVisa, setIsVisa] = useState(false);
  const [isMasterCard, setIsMasterCard] = useState(false);
  const postalCode = localStorage.getItem("postalCode") || "";
  const handleContinueClick = async (e) => {
    e.preventDefault();
    Auth.logout();
    localStorage.clear();
    navigate("/home");
  };

  const Redirect = () => {
    navigate("/ya-tengo-proteccionmovil");
  };

  useEffect(() => {
    if (!Auth.loggedIn()) {
      navigate("/home");
      return;
    }
    const timer = setTimeout(() => {
      setIsLoading(false); // Set your variable to false after 5 seconds
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  // {isLoading ? (
  //       <Spinner />
  //     ) : ()}

  useEffect(() => {
  window.dlMetri.push({
    event : "pageLoad",
    pagina: {
      canal : "Proteccion Movil Amigo Kit",
      seccion : "Pago",
      pageName : "Pago|Pago con tarjeta"
    },
    usuario : {
      CP : postalCode ? postalCode : "",
      region : reg ? reg : "",
      perfil : isDistributor ? "Distribuidor" : "Usuario"
    }
  })
  console.log('dlMetri /gracias por tu compra', window.dlMetri);
  window.dlMetri.push({
    event: "transaccionExitosa",
    pagina: {
      canal: "Proteccion Movil Amigo Kit",
      seccion: "Gracias por tu compra",
      pageName: "Gracias por tu compra",
    },
    usuario: {
      CP: postalCode ? postalCode : "",
      region: reg ? reg : "",
      perfil: isDistributor ? "Distribuidor" : "Usuario",
    },
    productos: [
      {
        categoria: "Servicios",
        subcategoria: "Seguridad",
        nombre: "Proteccion Movil Amigo Kit",
        monto: response.data.data.cargo.monto,
      },
    ],
    transaccion: {
      id: cleanId,
      metPago:
        response.data.data.cargo.tarjeta.producto === "credit"
          ? "Tarjeta de credito"
          : "Tarjeta de debito",
      metPagoVariante: response.data.data.cargo.procesador.adquiriente ? response.data.data.cargo.procesador.adquiriente : "",
      tipoTarjeta: tipoDeTarjetaMarca ? tipoDeTarjetaMarca : "",
    },
  });
  console.log("dlMetri tarjeta", window.window.dlMetri);
  }, []);

  useEffect(() => {
  if (!msi == 0){
    setIsMsi(true);
  } 
  if (tipoDeTarjetaMarca === "Visa") {
    setIsVisa(true);
  } else if (tipoDeTarjetaMarca === "Mastercard") {
    setIsMasterCard(true);
  }
  }, []);
  return (
    <section className="font-gotham min-h-screen place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      <Banner />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="text-[#6D80A4] flex flex-col phone:row-span-4  laptop:col-span-6 py-10 ">
          <h1 className="text-2xl md:text-3xl text-primary font-semibold lg:py-6 text-center">
            ¡Gracias por tu compra!
          </h1>

          <div className="flex flex-col justify-start items-center text-left pt-4 text-center text-center font-thin px-6 laptop:w-[40vw]">
            <p className="phone:text-sm laptop:text-lg">
              Recibiras tu contrato y los detalles del servicio <br />
              por correo electrónico.
            </p>
          </div>

          <div className="flex flex-col m-auto">
            <div className="flex justify-start items-start text-left pt-4 text-center text-center font-thin px-6 phone:px-9">
              <p className="phone:text-sm laptop:text-lg">
                <span className="font-semibold ">Titular:</span> {titular}
              </p>
            </div>
            <div className="flex justify-start items-start text-left pt-4 text-center text-center font-thin px-6 phone:px-9">
              <p className="phone:text-sm laptop:text-lg">
                <span className="font-semibold "> Correo electrónico:</span>{" "}
                {email}
              </p>
            </div>
            <div className="flex justify-start items-start text-left pt-4 text-center text-center font-thin px-6 phone:px-9">
              <p className="phone:text-sm laptop:text-lg">
                <span className="font-semibold "> Monto pagado: </span>$
                {insuranceCost}
              </p>
            </div>
            <div className="flex items-center text-left pt-4 font-thin px-6 phone:px-9">
              <p className="phone:text-sm font-semibold laptop:text-lg flex items-center gap-2">
                Número de tarjeta:
                {isMasterCard ? (
                  <img src={MasterCard} 
                  alt="MasterCard Logo" className="h-5 w-auto inline-block" />
                ) : null}
                {isVisa ? (
                  <img src={Visa} 
                  alt="Visa Logo" className="h-3 w-auto inline-block" />
                ) : null}
                ****{lastFourDigits}
              </p>
            </div>
            {isMsi ? (
            <div className="flex justify-start items-start text-left pt-4 text-center text-center font-thin px-6 phone:px-9">
              <p className="phone:text-sm font-semibold laptop:text-lg">
                Meses Sin Intereses: 6 pagos de ${(insuranceCost / 6).toFixed(2)}
              </p>
            </div>
            ) : null}
          </div>

          <div className="pt-8 text-sm laptop:text-base laptop:pt-[2rem] laptop:pb-[1rem] text-center">
            <button
              onClick={handleContinueClick}
              className=" font-thin bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6 laptop:w-60"
            >
              Aceptar
            </button>
          </div>

          <div className="flex flex-col justify-start items-center text-left text-center text-center font-thin px-6 cursor-pointer phone:pt-3">
            <p className="text-xs text-primary" onClick={Redirect}>
              Descargar confirmación de compra
            </p>
          </div>
        </div>
      )}
    </section>
  );
}
