import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Banner from "../components/sharedComponents/Banner";
import Spinner from "../components/sharedComponents/Spinner";
import Auth from "../utils/auth";
export default function PaymentDeclined3DS() {
  const titular = localStorage.getItem("titular");
  const lastFourDigits = localStorage.getItem("lastFourDigits");
  const insuranceCost = localStorage.getItem("insuranceCost");
  const email = localStorage.getItem("email");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // Initialize your variable
  const handleContinueClick = async (e) => {
    e.preventDefault();
    //console.log("Submit Triggered");
    Auth.logout();
    localStorage.clear();

    navigate("/home");
  };

  useEffect(() => {
    //if (!Auth.loggedIn()) {
    //navigate("/home");
    //return;
    //}
    const timer = setTimeout(() => {
      setIsLoading(false); // Set your variable to false after 5 seconds
    }, 500);

    // Cleanup function to clear the timer if the component is unmounted before the 5 seconds are up
    return () => clearTimeout(timer);
  }, []);

  // {isLoading ? (
  //       <Spinner />
  //     ) : ()}

  return (
    <section className="font-gotham min-h-screen place-items-center place-content-start grid phone:grid-cols-1 laptop:grid-cols-8">
      {/* <div className="phone:row-span-1 laptop:col-span-2 flex items-center justify-center bg-red-500 laptop:h-screen phone:h-1/8 overflow-hidden">
        <img
          src={protectMovilImg}
          alt="home"
          className="laptop:w-full h-full phone:h- object-cover "
        />
      </div> */}

      <Banner />
      {/* <div
        className="object-cover phone:row-span-1 laptop:col-span-2 flex items-center justify-center bg-red-500 phone:h-1/4  bg-top-quarter"
        style={{ backgroundImage: `url(${protectMovilImg})` }}
      /> */}

      {isLoading ? (
        <Spinner />
      ) : (
        <div className="text-[#6D80A4] flex flex-col phone:row-span-4  laptop:col-span-6 py-10 ">
          <h1 className="text-2xl md:text-3xl text-primary font-semibold lg:py-6 text-center">
            Su pago ha sido rechazado
          </h1>

          <div className="flex flex-col justify-start items-center text-left pt-4 text-center text-center font-thin px-6 laptop:w-[40vw]">
            <p className="phone:text-sm laptop:text-lg">
              Lo sentimos, tu pago no pudo ser procesado. Por favor, intenta de nuevo o realiza el pago en efectivo.
            </p>
          </div>

          {/*
          <div className="flex flex-col m-auto">
            <div className="flex justify-start items-start text-left pt-4 text-center text-center font-thin px-6 phone:px-9">
              <p className="phone:text-sm laptop:text-lg">
                <span className="font-semibold ">Titular:</span> {titular}
              </p>
            </div>
            <div className="flex justify-start items-start text-left pt-4 text-center text-center font-thin px-6 phone:px-9">
              <p className="phone:text-sm laptop:text-lg">
                <span className="font-semibold "> Correo electrónico:</span>{" "}
                {email}
              </p>
            </div>
            <div className="flex justify-start items-start text-left pt-4 text-center text-center font-thin px-6 phone:px-9">
              <p className="phone:text-sm laptop:text-lg">
                <span className="font-semibold "> Monto pagado: </span>$
                {insuranceCost}
              </p>
            </div>
            <div className="flex justify-start items-start text-left pt-4 text-center text-center font-thin px-6 phone:px-9">
              <p className="phone:text-sm font-semibold laptop:text-lg">
                Número de tarjeta: ************{lastFourDigits}
              </p>
            </div>
          </div>
          */}

          <div className="pt-8 text-sm laptop:text-base laptop:pt-[2rem] laptop:pb-[1rem] text-center">
            <button
              onClick={handleContinueClick}
              className=" font-thin bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-md md:py-3 md:px-6 laptop:w-48"
            >
              Intertar de nuevo
            </button>
          </div>

          {/*
          <div className="flex flex-col justify-start items-center text-left text-center text-center font-thin px-6 cursor-pointer phone:pt-3">
            <p className="text-xs text-primary">
              Descargar confirmación de compra
            </p>
          </div>
        */}
        </div>
      )}
    </section>
  );
}
